import React, { useState, useEffect } from "react";
import axios from 'axios';
import OverlayBannerContent from "../OverlayBannerContent";
import PreparatoryCard from "./PreparatoryCard";
import BookDemo from "../../home/BookDemo";
import ContactUs from "../../home/ContactUs";
import FAQ from "../../home/FAQ";

const fileUrl = "https://www.harshitamusicacademy.com/files";

const AllInOne = () => {

  const[courseCards, setCourseCards]= useState([]);

  useEffect(()=>{
    axios.get("https://www.harshitamusicacademy.com/adminPanel/API/all-in-one-course.php").then((response)=>{
      setCourseCards(response.data.AllInOneCourse);
  })
  },[]);

  return (
    <>
      <div className="KSM_Banner"
      style={{
        backgroundImage: `url('${fileUrl}/banners/all_in_one_course_banner.webp')`,
      }}>
        <div className="container">
          <h1 className="wow fadeInUp">All-in-One Course</h1>
          <OverlayBannerContent />
        </div>
      </div>
      <div className="HSM_Course_Particular HSM_Tuitions HSM_CoursesSlider">
        <div className="container">
          <h2 className="wow fadeInUp">The Ultimate Musical Journey</h2>
          <p className="wow fadeInUp" style={{marginBottom: '20px'}}>Our All-in-One Course is an immersive, six-month program designed to provide comprehensive training across multiple musical disciplines. This program is structured into three levels: Beginner, Intermediate, and Advanced, ensuring a progressive learning experience tailored to your skills and aspirations.</p>
          <div className="HSM_Course_paricularSelected">
            <div className="HSM_Course_paricularSelectedSolo active">
              <PreparatoryCard courseCards={courseCards} />
            </div>
          </div>

          <div className="HSM_Course_Virtuoso HSM_Course_paricularSelected">
            <div className="HSM_Course_paricularSelectedSolo active">
              <p className="vistuosoCourseCardBtm wow fadeInUp">The Virtuoso Course is designed to provide a bespoke learning experience, ensuring each student achieves their highest potential.<br/>Join us at Harshita School of Music and embark on a musical journey tailored just for you.</p>
              <p className="vistuosoCourseCardBtm wow fadeInUp">Embark on a transformative musical journey with our All-in-One Course and unlock your full potential as a versatile and accomplished musician.<br/>Join us at Harshita School of Music and experience unparalleled music education in Jaipur.</p>
            </div>
          </div>
        </div>
      </div>

      <ContactUs />

      <BookDemo />

      <FAQ />
    </>
  );
};

export default AllInOne;
