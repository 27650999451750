import React, {useState, useEffect} from 'react';
import axios from 'axios';


const ContactFixed = () => {

    const [phoneicon, setPhoneicon] = useState([]);

    useEffect(()=>{
        axios.get("https://www.harshitamusicacademy.com/adminPanel/API/contact.php").then((response)=>{
            setPhoneicon(response.data.Contact);
      })
    },[]);

    return(
        <>
        <div className='HSM_ContactIconFixed wow fadeInUp'>
        {phoneicon.map((res)=>
        <>
            <a href={`tel: ${res.HSMContact_ContactNumberPrimary}`}><i className='fa fa-phone'></i></a>
        </>
        )}
        </div>
        </>
    );
}


export default ContactFixed;