import React from "react";

const CourseOffering = ({hsmOfflinePage}) => {
  return (
    <>
      <div className="HSM_HSMCourseOffering HSM_AboutUs HSM_CoursesSlider">
        <div className="container">
          <h2 className="wow fadeInUp">{hsmOfflinePage.HSMOfflinePage_CourseOfferings_MainTitle}</h2>
          <p className="wow fadeInUp">{hsmOfflinePage.HSMOfflinePage_CourseOfferings_Description}</p>
          <div className="HSM_HSMCourseOfferingListing HSM_MasteryCourseListing wow fadeInUp">
            <h4>{hsmOfflinePage.HSMOfflinePage_CourseOfferings_GroupClassTitle}</h4>
            <p>{hsmOfflinePage.HSMOfflinePage_CourseOfferings_GroupClassDescription}</p>
            <ul>
              <li><h6 className="listingTitle">{hsmOfflinePage.HSMOfflinePage_CourseOfferings_GroupClassFeatureTitle1}</h6><span className="listingText">{hsmOfflinePage.HSMOfflinePage_CourseOfferings_GroupClassFeatureDescription1}</span></li>
              <li><h6 className="listingTitle">{hsmOfflinePage.HSMOfflinePage_CourseOfferings_GroupClassFeatureTitle2}</h6><span className="listingText">{hsmOfflinePage.HSMOfflinePage_CourseOfferings_GroupClassFeatureDescription2}</span></li>
              <li><h6 className="listingTitle">{hsmOfflinePage.HSMOfflinePage_CourseOfferings_GroupClassFeatureTitle3}</h6><span className="listingText">{hsmOfflinePage.HSMOfflinePage_CourseOfferings_GroupClassFeatureDescription3}</span></li>
              <li><h6 className="listingTitle">{hsmOfflinePage.HSMOfflinePage_CourseOfferings_GroupClassFeatureTitle4}</h6><span className="listingText">{hsmOfflinePage.HSMOfflinePage_CourseOfferings_GroupClassFeatureDescription4}</span></li>
              <li><h6 className="listingTitle">{hsmOfflinePage.HSMOfflinePage_CourseOfferings_GroupClassFeatureTitle5}</h6><span className="listingText">{hsmOfflinePage.HSMOfflinePage_CourseOfferings_GroupClassFeatureDescription5}</span></li>
              <li><h6 className="listingTitle">{hsmOfflinePage.HSMOfflinePage_CourseOfferings_GroupClassFeatureTitle6}</h6><span className="listingText">{hsmOfflinePage.HSMOfflinePage_CourseOfferings_GroupClassFeatureDescription6}</span></li>
              <li><h6 className="listingTitle">{hsmOfflinePage.HSMOfflinePage_CourseOfferings_GroupClassFeatureTitle7}</h6><span className="listingText">{hsmOfflinePage.HSMOfflinePage_CourseOfferings_GroupClassFeatureDescription7}</span></li>
              <li><h6 className="listingTitle">{hsmOfflinePage.HSMOfflinePage_CourseOfferings_GroupClassFeatureTitle8}</h6><span className="listingText">{hsmOfflinePage.HSMOfflinePage_CourseOfferings_GroupClassFeatureDescription8}</span></li>
              <li><h6 className="listingTitle">{hsmOfflinePage.HSMOfflinePage_CourseOfferings_GroupClassFeatureTitle9}</h6><span className="listingText">{hsmOfflinePage.HSMOfflinePage_CourseOfferings_GroupClassFeatureDescription9}</span></li>
            </ul>
          </div>
          <div className="HSM_HSMCourseOfferingListing HSM_MasteryCourseListing wow fadeInUp">
            <h4>{hsmOfflinePage.HSMOfflinePage_CourseOfferings_SoloClassTitle}</h4>
            <p>{hsmOfflinePage.HSMOfflinePage_CourseOfferings_SoloClassDescription}</p>
            <ul>
              <li><h6 className="listingTitle">{hsmOfflinePage.HSMOfflinePage_CourseOfferings_SoloClassFeatureTitle1}</h6><span className="listingText">{hsmOfflinePage.HSMOfflinePage_CourseOfferings_SoloClassFeatureDescription1}</span></li>
              <li><h6 className="listingTitle">{hsmOfflinePage.HSMOfflinePage_CourseOfferings_SoloClassFeatureTitle2}</h6><span className="listingText">{hsmOfflinePage.HSMOfflinePage_CourseOfferings_SoloClassFeatureDescription2}</span></li>
              <li><h6 className="listingTitle">{hsmOfflinePage.HSMOfflinePage_CourseOfferings_SoloClassFeatureTitle3}</h6><span className="listingText">{hsmOfflinePage.HSMOfflinePage_CourseOfferings_SoloClassFeatureDescription3}</span></li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseOffering;
