import React, {useState, useEffect} from 'react';
import axios from 'axios';

const ContactDetails = () =>{

    const[contact,setContact]= useState([]);
  
    useEffect(()=>{
        axios.get("https://www.harshitamusicacademy.com/adminPanel/API/contact.php").then((response)=>{
        setContact(response.data.Contact);
    })
    },[]);

    return(
        <>
        <div className="HSM_ContactDetails wow fadeInUp">
            {contact.map((res) => (
            <ul>
                <li>
                    <i className="fa fa-phone"></i>
                    <a style={{marginRight: '5px',}} href={`tel: ${res.HSMContact_ContactNumberPrimary}`} target="_blank" rel="noreferrer">{res.HSMContact_ContactNumberPrimary}</a>
                    ,<a style={{marginLeft: '5px',}} href={`tel: ${res.HSMContact_ContactNumberSecondary}`} target="_blank" rel="noreferrer">{res.HSMContact_ContactNumberSecondary}</a>
                </li>
                <li>
                    <i className="fa fa-envelope-o"></i><span>Founder's Email : </span>
                    <a href={`mailto: ${res.HSMContact_FounderEmail}`} target="_blank" rel="noreferrer">{res.HSMContact_FounderEmail}</a>
                </li>
                <li>
                    <i className="fa fa-envelope-o"></i>
                    <a href={`mailto: ${res.HSMContact_EmailPrimary}`} target="_blank" rel="noreferrer">{res.HSMContact_EmailPrimary}</a>
                </li>
                <li>
                    <i className="fa fa-envelope-o"></i>
                    <a href={`mailto: ${res.HSMContact_EmailSecondary}`} target="_blank" rel="noreferrer">{res.HSMContact_EmailSecondary}</a>
                </li>
                <li>
                    <i className="fa fa-clock-o"></i>
                    Monday to Friday : {res.HSMContact_TimingMTF} <br />Saturday : {res.HSMContact_TimingSt} <br />Sunday : {res.HSMContact_TimingSn}
                </li>
            </ul>
            ))}
        </div>
        </>
    )
}

export default ContactDetails