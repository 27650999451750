import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';

const fileUrl = "https://www.harshitamusicacademy.com/files";

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const[faqs,setFaqs]= useState([]);
  
  useEffect(()=>{
    axios.get("https://www.harshitamusicacademy.com/adminPanel/API/faqs.php").then((response)=>{
      setFaqs(response.data.Faqs);
  })
  },[]);

  const handleAccordionClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <>
      <div className="HSM_Faq HSM_CoursesSlider"
      style={{
        backgroundImage: `url('${fileUrl}/banners/faqs_bg.png')`,
      }}>
        <div className="container">
          <h2 className="wow fadeInUp">Faq's</h2>
          <div className="HSM_Faq_Accordian wow fadeInUp">
            {faqs.slice(0, 4).map((Faqs, index) => (
            <div key={index} className={`HSM_Faq_Accordian_Item ${activeIndex === index ? 'active' : ''}`}>
              <button className="HSM_Faq_Accordian_Question" onClick={() => handleAccordionClick(index)}>
                {Faqs.Faqs_Question}
              </button>
              <div className={`HSM_Faq_Accordian_Answer ${activeIndex === index ? 'active' : ''}`} dangerouslySetInnerHTML={{ __html: Faqs.Faqs_Answer }} />
            </div>
          ))}
          </div>
          <div className="HSM_HeaderDemoBtn wow fadeInUp">
            <Link to="/faqs" className="ankrBtn">Read All</Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default FAQ;
