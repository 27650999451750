import React, { useState, useEffect } from "react";
import axios from 'axios';
import OverlayBannerContent from "../OverlayBannerContent";
import AllSoloCardsInstruction from "../AllSoloCardsInstruction";
import PreparatoryCard from "./PreparatoryCard";
import InterMediateCard from "./InterMediateCard";
import AdvancedCard from "./AdvancedCard";
import GroupCourseCard from "./GroupCourseCard";
import VirtuosoCard from "./VirtuosoCard";
import VirtuosoAdvantages from '../VirtuosoAdvantages';
import BookDemo from "../../home/BookDemo";
import ContactUs from "../../home/ContactUs";
import FAQ from "../../home/FAQ";

const fileUrl = "https://www.harshitamusicacademy.com/files";

const PlaybackStageSinging = () => {

  const[courseCards, setCourseCards]= useState([]);

  useEffect(()=>{
    axios.get("https://www.harshitamusicacademy.com/adminPanel/API/playback-singing-course.php").then((response)=>{
      setCourseCards(response.data.PlaybackStageSingingCourse);
  })
  },[]);

  const [activeClassType, setActiveClassType] = useState(null);

  const handleClassTypeClick = (classType) => {
    setActiveClassType(classType);
  };

  return (
    <>
      <div className="KSM_Banner"
      style={{
        backgroundImage: `url('${fileUrl}/banners/playback_stage_singing_banner.webp')`,
      }}>
        <div className="container">
          <h1 className="wow fadeInUp">Playback Stage Singing</h1>
          <OverlayBannerContent />
        </div>
      </div>
      <div className="HSM_Course_Particular HSM_Tuitions HSM_CoursesSlider">
        <div className="container">
          <div className="HSM_Course_particularDesc">
            <div className="HSM_Course_particularDescInner wow fadeInUp">
              <h6>What it Means</h6>
              <p>Playback Stage Singing is a comprehensive program that prepares singers for both live performances and studio recordings, blending Western vocals, technical singing, Indian classical vocals, and Bollywood music.</p>
            </div>
            <div className="HSM_Course_particularDescInner wow fadeInUp">
              <h6>Training Involves</h6>
              <ul>
                <li>Strong vocal techniques for diverse genres.</li>
                <li>Microphone techniques and studio etiquette.</li>
                <li>Performance skills for stage and studio.</li>
                <li>Training in Indian classical, pop, rock, and more.</li>
                <li>Opportunities for recording demos and performing live.</li>
              </ul>
            </div>
            <div className="HSM_Course_particularDescInner wow fadeInUp">
              <h6>Other Details</h6>
              <p>This course is ideal for those aspiring to become professional playback singers. It offers a well-rounded education, preparing students for various aspects of the music industry.</p>
            </div>
          </div>
          <div className="HSM_Course_particularSelect wow fadeInUp">
            <h5>Select Your Class Type</h5>
            <p>(click the buttons below to select)</p>
            <div className="row HSM_Course_particularSelectAln">
              <div
                className={`HSM_Course_particularSelectBtn ${activeClassType === 'solo' ? 'active' : ''}`}
                onClick={() => handleClassTypeClick('solo')}
              >
                <h6>Solo</h6>
              </div>
              <div
                className={`HSM_Course_particularSelectBtn ${activeClassType === 'group' ? 'active' : ''}`}
                onClick={() => handleClassTypeClick('group')}
              >
                <h6>Group</h6>
              </div>
            </div>
          </div>
          <div className="HSM_Course_paricularSelected">
            <div className={`HSM_Course_paricularSelectedSolo ${activeClassType === 'solo' ? 'active' : ''}`}>
              <h5 className="HSM_Course_paricularSelectedSoloTitle wow fadeInUp">Please refer to this section where you find * and **</h5>
              <AllSoloCardsInstruction />
            </div>
          </div>
          <div className="HSM_Course_paricularSelected">
            <div className={`HSM_Course_paricularSelectedSolo ${activeClassType === 'solo' ? 'active' : ''}`}>
              <h6 className="wow fadeInUp">Preparatory</h6>
              <ul className="wow fadeInUp">
                <li><span className="HSM_Course_particularListTitle">What We Teach</span>Basic vocal techniques for various genres, introduction to playback singing, basic performance skills, breath control, and basic music theory.</li>
                <li><span className="HSM_Course_particularListTitle">Who Can Enroll</span>Beginners and those new to singing.</li>
                <li><span className="HSM_Course_particularListTitle">Learning Outcomes</span>Understanding of basic vocal techniques, ability to perform simple playback songs, foundational performance skills.</li>
              </ul>
              <PreparatoryCard courseCards={courseCards} />
            </div>
          </div>
          <div className="HSM_Course_paricularSelected">
            <div className={`HSM_Course_paricularSelectedSolo ${activeClassType === 'solo' ? 'active' : ''}`}>
              <h6 className="wow fadeInUp">Intermediate</h6>
              <ul className="wow fadeInUp">
                <li><span className="HSM_Course_particularListTitle">What We Teach</span>Advanced vocal techniques, genre-specific styles (Western, Indian classical, Bollywood), intermediate performance skills, studio techniques, and intermediate music theory.</li>
                <li><span className="HSM_Course_particularListTitle">Who Can Enroll</span>Students with some vocal training or experience in singing.</li>
                <li><span className="HSM_Course_particularListTitle">Learning Outcomes</span>Enhanced vocal versatility, ability to perform more complex songs, improved performance and studio skills, intermediate theory knowledge.</li>
              </ul>
              <InterMediateCard courseCards={courseCards} />
            </div>
          </div>
          <div className="HSM_Course_paricularSelected">
            <div className={`HSM_Course_paricularSelectedSolo ${activeClassType === 'solo' ? 'active' : ''}`}>
              <h6 className="wow fadeInUp">Advanced</h6>
              <ul className="wow fadeInUp">
                <li><span className="HSM_Course_particularListTitle">What We Teach</span>Mastery of playback singing techniques, professional performance and studio skills, advanced genre-specific styles, professional preparation.</li>
                <li><span className="HSM_Course_particularListTitle">Who Can Enroll</span>Experienced singers aiming for professional careers in playback singing.</li>
                <li><span className="HSM_Course_particularListTitle">Learning Outcomes</span>Professional-level singing ability, mastery of playback styles, readiness for professional performances and recordings.</li>
              </ul>
              <AdvancedCard courseCards={courseCards} />
            </div>
          </div>

          <div className="HSM_Course_Virtuoso HSM_Course_paricularSelected">
            <div className={`HSM_Course_paricularSelectedSolo ${activeClassType === 'solo' ? 'active' : ''}`}>
              <VirtuosoAdvantages />
              <VirtuosoCard courseCards={courseCards} />
              <p className="vistuosoCourseCardBtm">The Virtuoso Course is designed to provide a bespoke learning experience, ensuring each student achieves their highest potential. Join us at Harshita School of Music and embark on a musical journey tailored just for you.</p>
            </div>
          </div>
          
          <div className="HSM_Course_paricularSelected">
            <div className={`HSM_Course_paricularSelectedGroup ${activeClassType === 'group' ? 'active' : ''}`}>
              <GroupCourseCard courseCards={courseCards} />
              <div class="HSM_ApplicationFormNote">
                <p className="wow fadeInUp"><span>NOTE : </span>Students will be allotted the batch according to the level of the student.</p>
              </div>
              <div className="HSM_WesternVocalsGroupAdv">
                <h2 className="wow fadeInUp">Advantages</h2>
                <div className="row HSM_TuitionsAln">
                  <div className="HSM_TuitionsCols wow fadeInUp col-4">
                    <div className="HSM_TuitionsColsInner">
                      <div className="HSM_CoursesCards">
                        <h6>Collaboration and Teamwork</h6>
                        <ul>
                          <li>Learn to work and create music with others.</li>
                          <li>Enhance listening skills and group coordination.</li>
                          <li>Develop the ability to adapt and contribute effectively in a collective setting.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="HSM_TuitionsCols wow fadeInUp col-4">
                    <div className="HSM_TuitionsColsInner">
                      <div className="HSM_CoursesCards">
                        <h6>Performance Skills</h6>
                        <ul>
                          <li>Build confidence and stage presence through regular group practice.</li>
                          <li>Gain experience in performing in front of peers.</li>
                          <li>Prepare for public performances and auditions.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="HSM_TuitionsCols wow fadeInUp col-4">
                    <div className="HSM_TuitionsColsInner">
                      <div className="HSM_CoursesCards">
                        <h6>Diverse Learning</h6>
                        <ul>
                          <li>Exposure to various instruments and musical styles.</li>
                          <li>Broaden musical understanding and versatility.</li>
                          <li>Experience the dynamics of different roles within an ensemble.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="HSM_TuitionsCols wow fadeInUp col-4">
                    <div className="HSM_TuitionsColsInner">
                      <div className="HSM_CoursesCards">
                        <h6>Motivation and Support</h6>
                        <ul>
                          <li>Benefit from the encouragement and camaraderie of fellow musicians.</li>
                          <li>Enjoy a more enjoyable and inspiring learning process.</li>
                          <li>Stay motivated by sharing progress and achievements with peers.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="HSM_TuitionsCols wow fadeInUp col-4">
                    <div className="HSM_TuitionsColsInner">
                      <div className="HSM_CoursesCards">
                        <h6>Enhanced Musicality</h6>
                        <ul>
                          <li>Improve timing, tuning, and coordination by playing in sync with others.</li>
                          <li>Develop a deeper understanding of ensemble dynamics.</li>
                          <li>Refine musical interpretation and expression through group feedback.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="HSM_TuitionsCols wow fadeInUp col-4">
                    <div className="HSM_TuitionsColsInner">
                      <div className="HSM_CoursesCards">
                        <h6>Social Interaction</h6>
                        <ul>
                          <li>Build lasting friendships and connections with other musicians.</li>
                          <li>Enjoy a sense of community and belonging.</li>
                          <li>Participate in collaborative projects and performances.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="HSM_TuitionsCols wow fadeInUp col-4">
                    <div className="HSM_TuitionsColsInner">
                      <div className="HSM_CoursesCards">
                        <h6>Creative Growth</h6>
                        <ul>
                          <li>Explore new ideas and approaches through group discussions and collaborations.</li>
                          <li>Encourage creativity and innovation in a supportive environment.</li>
                          <li>Learn from the diverse perspectives and experiences of peers.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="HSM_TuitionsCols wow fadeInUp col-4">
                    <div className="HSM_TuitionsColsInner">
                      <div className="HSM_CoursesCards">
                        <h6>Cost-Effective Learning</h6>
                        <ul>
                          <li>Benefit from high-quality instruction at a lower cost compared to individual lessons.</li>
                          <li>Access a variety of learning resources and materials in a group setting.</li>
                          <li>Share the cost of professional coaching and facilities with other students.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <p className="wow fadeInUp">Hurry up! Our batches are almost full.</p>
              <p className="wow fadeInUp">Contact or Call us now to check your seat availability with <span className="batchTimings">batch timings.</span></p>
            </div>
          </div>
        </div>
      </div>

      <ContactUs />

      <BookDemo />

      <FAQ />
    </>
  );
};

export default PlaybackStageSinging;
