import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";

const PopularCoursesSidebar = () => {

  const[popularCourses,setPopularCourses]= useState([]);

  useEffect(()=>{
    axios.get("https://www.harshitamusicacademy.com/adminPanel/API/popular-courses.php").then((response)=>{
        setPopularCourses(response.data.PopularCourses);
    })
  },[]);

  return (
    <>
    <div className='KSM_AllBlogRightInner wow fadeInUp'>
        <h5>Popular Courses</h5>
        <div className="KSM_AllBlogRightCard">
        {popularCourses.slice(0,4).map((popularcourses, index) => (
            <div className="KSM_AllBlogRightCardInner" key={index}>
                <div className="KSM_AllBlogRightCardInnerImg">
                    <img 
                    src={popularcourses.UsersMedia_MediaUrl}
                    alt={popularcourses.CourseCardsCarousel_Title} />
                </div>
                <div className="KSM_AllBlogRightCardInnerContent">
                    <h6>{popularcourses.CourseCardsCarousel_Title}</h6>
                    <div className="HSM_CourseReadMore">
                    <Link  
                    to={`/${popularcourses.CourseCardsCarousel_PageLinkTo}`} 
                    className="ankrBtn">Read More <i className="fa fa-angle-right"></i></Link>
                    </div>
                </div>
            </div>
        ))}
        </div>
    </div>
    </>
  );
};

export default PopularCoursesSidebar;
