import React from "react";

import { Link } from "react-router-dom";

const fileUrl = "https://www.harshitamusicacademy.com/files";

const BannerHomeOverlayContent = () => {
  return (
    <>
    <div className="row KSM_BannerAlg">
        <div className="KSM_BannerTags wow fadeInUp col-4">
            <div className="KSM_BannerTagsAln">
                <div className="KSM_BannerTagsImg">
                <img 
                src={`${fileUrl}/icons/enroll_now_yellow.png`}
                alt="banner-icon"
                width={60}
                height={60}
                objectFit="contain" />
                </div>
                <div className="KSM_BannerTagsCont">
                <h6>Enroll Now</h6>
                <Link to="/admission-form">Read More <i className="fa fa-angle-right"></i></Link>
                </div>
            </div>
        </div>
        <div className="KSM_BannerTags wow fadeInUp col-4">
            <div className="KSM_BannerTagsAln">
                <div className="KSM_BannerTagsImg">
                <img 
                src={`${fileUrl}/icons/contact_us_yellow.png`}
                alt="banner-icon"
                width={60}
                height={60}
                objectFit="contain" />
                </div>
                <div className="KSM_BannerTagsCont">
                <h6>Contact Us</h6>
                <Link to="/contact">Read More <i className="fa fa-angle-right"></i></Link>
                </div>
            </div>
        </div>
        <div className="KSM_BannerTags wow fadeInUp col-4">
            <div className="KSM_BannerTagsAln">
                <div className="KSM_BannerTagsImg">
                <img 
                src={`${fileUrl}/icons/book_demo_yellow.png`}
                alt="banner-icon"
                width={60}
                height={60}
                objectFit="contain" />
                </div>
                <div className="KSM_BannerTagsCont">
                <h6>Book A Demo</h6>
                <Link to="/contact">Read More <i className="fa fa-angle-right"></i></Link>
                </div>
            </div>
        </div>
    </div>
    </>
  );
};

export default BannerHomeOverlayContent;
