import React, {useState, useEffect} from 'react';
import axios from 'axios';

import Header from "../components/Header";
import Footer from "../components/Footer";

import MetaTags from "../components/MetaTags";

import ApplicationForm from '../components/ApplicationForm';

export default function ApplicationAdmForm() {

  const[seo, setSeo]= useState([]);
  
  useEffect(()=>{
    axios.get("https://www.harshitamusicacademy.com/adminPanel/API/seo.php").then((response)=>{
      setSeo(response.data.SEO);
  })
  },[]);

  const[admFormPage, setAdmFormPage]= useState([]);
  
  useEffect(()=>{
    axios.get("https://www.harshitamusicacademy.com/adminPanel/API/admission-form-page.php").then((response)=>{
      setAdmFormPage(response.data.AdmissionFormPage[0]);
  })
  },[]);
  
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant"
    });
  }, []);

  return (
    <>
    {seo.map((res) => (
      <MetaTags
      title={`${res.SEO_Title_AdmForm}`}
      description={`${res.SEO_Description_AdmForm}`}
      keywords={`${res.SEO_Keyword_AdmForm}`} />
    ))}

    <Header />

    <ApplicationForm admFormPage={admFormPage} />

    <Footer />
    </>
  );
}
