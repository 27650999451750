import React, { useState, useRef } from "react";
import axios from 'axios';
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import PhoneInput from "react-phone-input-2";
import ReCAPTCHA from "react-google-recaptcha";
import "react-phone-input-2/lib/style.css";

const fileUrl = "https://www.harshitamusicacademy.com/files";

const schema = yup.object().shape({
    name: yup.string().required("Name is required"),
    email: yup.string().email("Invalid email format").required("Email is required"),
    phone: yup
      .string()
      .required("Contact Number is required")
      .test("is-valid-phone", "Contact Number is invalid", (value) => {
        const phoneNumber = value.replace(/[^0-9]/g, "");
        return phoneNumber.length >= 10 && phoneNumber.length <= 15;
      }),
    message: yup.string().required("Message is required"),
});

const ContactForm = () => {
    const recaptchaRef = useRef(null);
    const [showPopup, setShowPopup] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        reset,
        setError,
        clearErrors
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            name: '',
            email: '',
            phone: '+91',
            message: ''
        }
    });

    const onSubmit = async (data) => {
        const recaptchaValue = recaptchaRef.current.getValue();
        if (!recaptchaValue) {
            setError("recaptcha", {
                type: "manual",
                message: "Please complete the reCAPTCHA"
            });
            return;
        }
        setIsLoading(true);

        try {
            const response = await axios.post('https://www.harshitamusicacademy.com/adminPanel/API/contact-form-send.php', {
                formdata: data,
                recaptcha: recaptchaValue
            });

            if (response.status === 200) {
                setShowPopup(true);
                reset({
                    name: '',
                    email: '',
                    phone: '+91',
                    message: ''
                });
                recaptchaRef.current.reset();
            } else {
                console.error('Failed to send message:', response.data);
            }
        } catch (error) {
            console.error('Error sending form data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleRecaptchaChange = (value) => {
        if (value) {
            clearErrors("recaptcha");
        }
    };

    return (
        <>
            <form method='POST' onSubmit={handleSubmit(onSubmit)}>
                <div className="inputtypes">
                    <input type="text" name="name" placeholder="Name" className="namebox" {...register("name")} />
                    {errors.name && <span>{errors.name.message}</span>}
                </div>
                <div className="inputtypes">
                    <input type="text" name="email" placeholder="Email" className="namebox" {...register("email")} />
                    {errors.email && <span>{errors.email.message}</span>}
                </div>
                <div className="inputtypes">
                    <Controller
                        name="phone"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <PhoneInput
                                country={"in"}
                                value={value}
                                onChange={onChange}
                                inputClass="namebox"
                                inputStyle={{ width: "100%" }}
                                specialLabel=""
                                enableSearch
                                disableSearchIcon
                                searchPlaceholder="Search"
                                renderStringAsHTML
                                dropdownStyle={{
                                    width: '300px',
                                    textAlign: 'left'
                                }}
                                containerClass="phone-input-container"
                                buttonClass="phone-input-button"
                                dropdownClass="phone-input-dropdown"
                            />
                        )}
                    />
                    {errors.phone && <span>{errors.phone.message}</span>}
                </div>
                <div className="inputtypes">
                    <textarea type="text" rows="7" name="message" placeholder="Message" className="namebox" {...register("message")}></textarea>
                    {errors.message && <span>{errors.message.message}</span>}
                </div>
                <div className="inputtypes">
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        // sitekey="6LcfUJYlAAAAAMZKLnki7CIqzNT2CjtmcNaLYfKa"      // localhost
                        sitekey="6LeeE_MpAAAAAMNnk2LK58Gr-ELSPIrHiW0zmQRM"         // live site harshitamusicacademy.com
                        //sitekey="6Lfv4g8qAAAAAFDtjZ4GUKk3uVx64RMAQn7o8WLF"       // live site harshitaschoolofmusic.com
                        onChange={handleRecaptchaChange}
                    />
                    {errors.recaptcha && <span>{errors.recaptcha.message}</span>}
                </div>
                <div className='submitform'>
                    <input type="submit" className='submit' value='SUBMIT' />
                </div>
            </form>
            {showPopup && (
                <div className="formOverlay">
                    <div className="popup">
                        <div className='popup-check-right'>
                            <i className='fa fa-check'></i>
                        </div>
                        <p>We’ll get back to you regarding demo confirmation as soon as possible.</p>
                        <button className="close_btn" onClick={() => setShowPopup(false)}>X</button>
                    </div>
                </div>
            )}
            {isLoading && (
                <div className='loading-overlay'>
                    <div className="loading-spinner">
                        <img src={`${fileUrl}/loading.gif`} alt="Loading..." />
                    </div>
                </div>
            )}
        </>
    )
}

export default ContactForm;
