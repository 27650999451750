import React from "react";

const fileUrl = "https://www.harshitamusicacademy.com/files";

const BankDetails = () => {

  const upiOptions = [
    {
      name: "Paytm",
      logo: "paytm_logo.png",
      qrCode: "QR_Code_PayTm.webp",
      bankingName: "Hritisha Rewadia",
      UpiID: "hritisha@paytm"
    },
    {
      name: "PhonePay",
      logo: "phone_pay_logo.png",
      qrCode: "QR_Code_PhonePay.webp",
      bankingName: "Hritisha Rewadia",
      UpiID: "8769373591@ybl"
    },
    {
      name: "GooglePay",
      logo: "google_pay_logo.png",
      qrCode: "QR_Code_googlePay.webp",
      bankingName: "Hritisha Rewadia",
      UpiID: "hritisharewadia@oksbi"
    }
  ];

  return (
    <>
    <div className="HSM_BankDetailsAln">
        <div className="HSM_BankDetailsLeft">
            <h5 className="wow fadeInUp">UPI Options</h5>
            <div className="HSM_BankDetailsLeftInner">
                {upiOptions.map((option, index) => (
                  <div key={index} className="HSM_BankDetailsColsCard wow fadeInUp col-4">
                    <div className="HSM_BankDetailsColsCardBank">
                      <h6>Pay with {option.name}</h6>
                      <div className="HSM_BankDetailsColsCardBankInner">
                        <div className="HSM_BankDetailsColsCardBankUPI">
                          <div className="PaymentTypeImg">
                            <img 
                              src={`${fileUrl}/payments/${option.logo}`}
                              alt="payment-logo"
                              width={70}
                              height={70}
                              objectFit="contain" />
                          </div>
                          <p>{option.bankingName}</p>
                          <p>{option.UpiID}</p>
                        </div>
                        <div className="HSM_BankDetailsColsCardBankUPI_QR">
                          <img 
                            src={`${fileUrl}/payments/${option.qrCode}`}
                            alt="payment-QR"
                            width={165}
                            height={165}
                            objectFit="contain" />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
        </div>
      </div>
    </>
  );
};

export default BankDetails;
