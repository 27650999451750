import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';

const fileUrl = "https://www.harshitamusicacademy.com/files";

const LatestBlogs = () => {

    const[blog, setBlog]= useState([]);
  
    useEffect(()=>{
        axios.get("https://www.harshitamusicacademy.com/adminPanel/API/posts.php").then((response)=>{
            setBlog(response.data.Posts);
    })
    },[]);

    const formatTitleForUrl = (title, id) => {
      const formattedTitle = title.replace(/\s+/g, '-').toLowerCase();
      return `${id}-${formattedTitle}`;
    };

  return (
    <>
      <div className="HSM_Latest_blog HSM_WCU HSM_CoursesSlider"
      style={{
        backgroundImage: `url('${fileUrl}/banners/latest_blog_bg.webp')`,
      }}>
        <div className="container">
          <h2 className="wow fadeInUp">Our Latest Blog</h2>
          <div className="HSM_Latest_BlogMain">
            <div className="row HSM_Latest_BlogAln">
                <div className="HSM_Latest_BlogCard wow fadeInUp col-3">
                    <div className="HSM_Latest_BlogCardInner HSM_LatestAllBlogBtn">
                        <h4>View All Blog</h4>
                        <div className="HSM_HeaderDemoBtn">
                            <Link to="/" className="ankrBtn">Explore <i className="fa fa-angle-right"></i></Link>
                        </div>
                    </div>
                </div>
                {blog.slice(0,3).map((blog, index) => (
                <div className="HSM_Latest_BlogCard wow fadeInUp col-3" key={index}>
                    <div className="HSM_Latest_BlogCardInner">
                        <div className="HSM_Latest_BlogCardInnerImg">
                            <img 
                            src={`${blog.UsersMedia_MediaUrl}`} 
                            alt={`${blog.Posts_CategoryName}`} />
                        </div>
                        <div className="HSM_Latest_BlogCardInnerContent">
                            <h6>{`${blog.Posts_Title}`}</h6>
                            <p>{`${blog.Posts_ShortDescription}`}</p>
                            <div className="HSM_HeaderDemoBtn">
                                <Link 
                                to={`/blog/${formatTitleForUrl(blog.Posts_Title, blog.Posts_ID)}`} 
                                className="ankrBtn">Explore <i className="fa fa-angle-right"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LatestBlogs;