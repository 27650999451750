import React from "react";
import { Link } from "react-router-dom";

const fileUrl = "https://www.harshitamusicacademy.com/files";

const MasteryCourse = ({ homePage }) => {

  const boxes = [
    { 
      heading: "Master Multiple Instruments",
      icon: "master_multiple_instruments_black.png"
    },
    { 
      heading: "Explore Vocal Excellence",
      icon: "explore_vocal_excellence_black.png"
    },
    { 
      heading: "Enhance Musical Understanding",
      icon: "enhance_musical_understanding_black.png"
    },
    { 
      heading: "Studio Performance Experience",
      icon: "studio_and_performance_experience_black.png"
    },
    { 
      heading: "Music Industry Insights",
      icon: "music_industry_insights_black.png"
    }
  ];

  return (
    <>
      <div className="HSM_MasteryCourse HSM_CoursesSlider"
      style={{
        backgroundImage: `url('${fileUrl}/banners/all_in_one_course_banner.webp')`,
      }}>
        <div className="container">
          <h2 className="wow fadeInUp">{homePage.HomePage_AllInOneMasteryCourseMainTitle}</h2>
          <p className="wow fadeInUp">{homePage.HomePage_AllInOneMasteryCourseDescription1}</p>
          <div className="HSM_MasteryCourseListing">
            <div className="row HSM_MasteryCourseBoxAlgn">
              {boxes.map((box, index) => (
                <div className="HSM_MasteryCourseBox wow fadeInUp col-5-eq" key={index}>
                  <div className="HSM_MasteryCourseBoxCard">
                    <h6>{box.heading}</h6>
                    <div className="HSM_MasteryCourseBoxIcon">
                      <img 
                        src={`${fileUrl}/icons/${box.icon}`}
                        alt={box.heading}
                        width={70}
                        height={70}
                        objectFit="contain" />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <p className="wow fadeInUp">{homePage.HomePage_AllInOneMasteryCourseDescription2}</p>
          <p className="wow fadeInUp">{homePage.HomePage_AllInOneMasteryCourseDescription3}</p>
          <div className="HSM_HeaderDemoBtn wow fadeInUp">
            <Link to="/all-in-one" className="ankrBtn">Read More</Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default MasteryCourse;
