import React from "react";
import { Link } from "react-router-dom";

const VirtuosoCard = ({ courseCards }) => {

  const filteredCourseCards = courseCards.filter(
    card => card.AllCoursesCards_Card_CourseLevel === "Virtuoso" && card.AllCoursesCards_Card_CourseType === "Solo"
  );

  const courseFinalCard = filteredCourseCards[0];

  return (
    <>
      <div className="row HSM_Course_paricularSelectedCardAln">
      {filteredCourseCards.map((res) => (
        <div className="HSM_Course_paricularSelectedCardcols wow fadeInUp col-12">
          <div className="HSM_VirtuosoCourseInner HSM_Course_paricularSelectedCardcolsInner">
            <ul className="HSM_VirtousoCourseListing">
              <li><span className="HSM_VirtousoCourseListingTitle">What You'll Learn : </span><p>Customized vocal training for Bollywood styles, recording techniques, and performance skills, aligned with your specific goals.</p></li>
              <li><span className="HSM_VirtousoCourseListingTitle">Who Can Enroll : </span><p>Aspiring Bollywood singers at any level.</p></li>
            </ul>
            <h6>Features</h6>
            <ul>
            {courseFinalCard.Features.map((feature, index) => (
              <li key={index}>
                {feature.AllCoursesCards_Features_FeatureTitle} : 
                <span>
                  {feature.AllCoursesCards_Features_FeatureValue.toLowerCase() === "yes" ? (
                      <i className="fa fa-check"></i>
                    ) : feature.AllCoursesCards_Features_FeatureValue.toLowerCase() === "no" ? (
                      <i className="fa fa-close"></i>
                    ) : (
                      feature.AllCoursesCards_Features_FeatureValue
                  )}
                </span>
              </li> 
            ))}
            </ul>
            <div className="HSM_Course_paricularSelectedCardcolsInnerPay">
              <div className="HSM_Course_paricularSelectedCardcolsInnerPayLeft">
                <div className="HSM_Course_paricularSelectedCardcolsInnerSessions">
                  <h6>Number of classes</h6>
                  <p>{courseFinalCard.AllCoursesCards_Card_CourseCardNumberOfClasses} Classes</p>
                </div>
                {/* <div className="HSM_Course_paricularSelectedCardcolsInnerPayBtn">
                  <a href={courseFinalCard.UsersMedia_MediaUrl} target="_blank" rel="noreferrer">Curriculum</a>
                </div> */}
                <div className="HSM_Course_paricularSelectedCardcolsInnerPayBtn">
                  <Link to="/admission-form">Enroll Now <i className="fa fa-angle-right"></i></Link>
                </div>
              </div>
              <div className="HSM_Course_paricularSelectedCardcolsInnerPayRight">
                <div className="HSM_Course_paricularSelectedCardcolsInnerPayBtn">
                  Rs.<span className="CoursePrice">{courseFinalCard.AllCoursesCards_Card_CourseCardBasePrice}/-</span><br/><span className="discountedCoursePrice">{courseFinalCard.AllCoursesCards_Card_CourseCardDiscountedPrice}/-</span>
                  <br/>
                <span className="pricePerClass">Rs. {courseFinalCard.AllCoursesCards_Card_CourseCardChargePerClass} / Per Class</span>
                </div>
                <div class="HSM_HeaderDemoBtn">
                  <Link to="/contact" className="ankrBtn">Book A Demo <i className="fa fa-angle-right"></i></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
      </div>
    </>
  );
};

export default VirtuosoCard;
