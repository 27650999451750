import React, { Component } from "react";
import Slider from "react-slick";

export default class AdvantagesSlider extends Component {
  render() {
    const { homePage } = this.props;
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      pauseOnHover: true,
      className: "rv_slider",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        }
      ]
    };

  return (
    <>
    <ul>
    <Slider {...settings}>

        <li className="wow fadeInUp">
          <h6 className="listingTitle">{homePage.HomePage_HSMAdvantages_Title1}</h6>
          <span className="listingText">{homePage.HomePage_HSMAdvantages_Description1}</span>
        </li>

        <li className="wow fadeInUp">
          <h6 className="listingTitle">{homePage.HomePage_HSMAdvantages_Title2}</h6>
          <span className="listingText">{homePage.HomePage_HSMAdvantages_Description2}</span>
        </li>

        <li className="wow fadeInUp">
          <h6 className="listingTitle">{homePage.HomePage_HSMAdvantages_Title3}</h6>
          <span className="listingText">{homePage.HomePage_HSMAdvantages_Description3}</span>
        </li>

        <li className="wow fadeInUp">
          <h6 className="listingTitle">{homePage.HomePage_HSMAdvantages_Title4}</h6>
          <span className="listingText">{homePage.HomePage_HSMAdvantages_Description4}</span>
        </li>

        <li className="wow fadeInUp">
          <h6 className="listingTitle">{homePage.HomePage_HSMAdvantages_Title5}</h6>
          <span className="listingText">{homePage.HomePage_HSMAdvantages_Description5}</span>
        </li>

        <li className="wow fadeInUp">
          <h6 className="listingTitle">{homePage.HomePage_HSMAdvantages_Title6}</h6>
          <span className="listingText">{homePage.HomePage_HSMAdvantages_Description6}</span>
        </li>

    </Slider>
    </ul>
    </>
   );
  }
}
