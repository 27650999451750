import React from "react";
import BookDemo from "./home/BookDemo";
import ContactUs from "./home/ContactUs";

const fileUrl = "https://www.harshitamusicacademy.com/files";


const Contact = () => {
  return (
    <>
      <div className="KSM_ContactPage_Banner KSM_Banner"
      style={{
        backgroundImage: `url('${fileUrl}/banners/contact_us_banner.webp')`,
      }}>
        <div className="container">
          <h1 className="wow fadeInUp">Book A Demo <br />Or <br />Contact Us!</h1>
        </div>
      </div>

      <BookDemo />

      <ContactUs />
    </>
  );
};

export default Contact;
