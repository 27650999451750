import React from "react";

import { Link } from "react-router-dom";

const AboutUsHome = ({ homePage }) => {
  return (
    <>
      <div className="HSM_AboutUs HSM_CoursesSlider">
        <div className="container">
          <h2 className="wow fadeInUp">{homePage.HomePage_AboutHSM_MainTitle}</h2>
          <p className="wow fadeInUp">{homePage.HomePage_AboutHSM_Description1}</p>
          <div className="HSM_MasteryCourseListing wow fadeInUp">
            <ul>
              <li><h6 className="listingTitle">{homePage.HomePage_AboutHSM_FeatureTitle1}</h6><span className="listingText">{homePage.HomePage_AboutHSM_FeatureDescription1}</span></li>
              <li><h6 className="listingTitle">{homePage.HomePage_AboutHSM_FeatureTitle2}</h6><span className="listingText">{homePage.HomePage_AboutHSM_FeatureDescription2}</span></li>
              <li><h6 className="listingTitle">{homePage.HomePage_AboutHSM_FeatureTitle3}</h6><span className="listingText">{homePage.HomePage_AboutHSM_FeatureDescription3}</span></li>
              <li><h6 className="listingTitle">{homePage.HomePage_AboutHSM_FeatureTitle4}</h6><span className="listingText">{homePage.HomePage_AboutHSM_FeatureDescription4}</span></li>
            </ul>
          </div>
          <p className="wow fadeInUp">{homePage.HomePage_AboutHSM_Description2}</p>
          <p className="wow fadeInUp">{homePage.HomePage_AboutHSM_Description3}</p>
          <div className="HSM_HeaderDemoBtn wow fadeInUp">
            <Link to="/about-us" className="ankrBtn">Book A Demo</Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUsHome;
