import React, {useState, useEffect} from 'react';
import axios from 'axios';

import Header from "../components/Header";
import Footer from "../components/Footer";

import MetaTags from "../components/MetaTags";

import CoursesSlider from "../components/home/CoursesSlider";
import MasteryCourse from "../components/home/MasteryCourse";
import Advantages from "../components/home/Advantages";
import TrainingCenter from "../components/home/TrainingCenter";
import HomeTuitions from "../components/home/HomeTuitions";
import WhyChooseUs from "../components/home/WhyChooseUs";
import Testimonials from "../components/home/Testimonials";
import LatestBlog from "../components/home/LatestBlog";
import AboutUs from "../components/home/AboutUs";
import ContactUs from "../components/home/ContactUs";
import BookDemo from "../components/home/BookDemo";
import FAQ from "../components/home/FAQ";

import EnrollNowButton from "../components/EnrollNowButton";
import StudentsCounter from "../components/StudentsCounter";

import HomeBannerSlider from "../components/home/HomeBannerSlider";

import InstaPosts from '../components/InstaPosts';

export default function Home() {

  const[seo, setSeo]= useState([]);
  
  useEffect(()=>{
    axios.get("https://www.harshitamusicacademy.com/adminPanel/API/seo.php").then((response)=>{
      setSeo(response.data.SEO);
  })
  },[]);

  const[homePage, setHomePage]= useState([]);
  
  useEffect(()=>{
    axios.get("https://www.harshitamusicacademy.com/adminPanel/API/home-page.php").then((response)=>{
      setHomePage(response.data.HomePage[0]);
  })
  },[]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant"
    });
  }, []);
  
  return (
    <>
    {seo.map((res) => (
      <MetaTags
      title={`${res.SEO_Title_Home}`}
      description={`${res.SEO_Description_Home}`}
      keywords={`${res.SEO_Keyword_Home}`} />
    ))}


    <EnrollNowButton />

    <Header />
    
    <HomeBannerSlider />

    <StudentsCounter />

    <CoursesSlider />

    <MasteryCourse homePage={homePage} />

    <Advantages homePage={homePage} />

    <TrainingCenter homePage={homePage} />

    <HomeTuitions homePage={homePage} />

    <WhyChooseUs homePage={homePage} />

    <Testimonials />

    <LatestBlog />

    <AboutUs homePage={homePage} />

    <ContactUs />

    <InstaPosts />

    <BookDemo />

    <FAQ />

    <Footer />
    </>
  );
}
