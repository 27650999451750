import React from "react";

const fileUrl = "https://www.harshitamusicacademy.com/files";

const About = () => {
  return (
    <>
      <div className="KSM_AboutPage_Banner KSM_Banner"
      style={{
        backgroundImage: `url('${fileUrl}/banners/about_us_banner.webp')`,
      }}>
        <div className="container">
          <h1 className="wow fadeInUp">About Us</h1>
        </div>
      </div>
    </>
  );
};

export default About;
