import React, { useState } from "react";

const fileUrl = "https://www.harshitamusicacademy.com/files";

const StudentSupportTabs = ({hsmOfflinePage}) => {
  
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const tabContent = [
    {
      title: `${hsmOfflinePage.HSMOfflinePage_StudentSupport_FeatureTitle1}`,
      className: "HSM_TabCounseMentor",
      content: `${hsmOfflinePage.HSMOfflinePage_StudentSupport_FeatureDescription1}`,
      icon: "/icons/hsm_counseling_and_mentorship_white.png"
    },
    {
      title: `${hsmOfflinePage.HSMOfflinePage_StudentSupport_FeatureTitle2}`,
      className: "HSM_TabPerOppo",
      content: `${hsmOfflinePage.HSMOfflinePage_StudentSupport_FeatureDescription2}`,
      icon: "/icons/hsm_performance_ppportunities_white.png"
    },
    {
      title: `${hsmOfflinePage.HSMOfflinePage_StudentSupport_FeatureTitle3}`,
      className: "HSM_TabPracFacility",
      content: `${hsmOfflinePage.HSMOfflinePage_StudentSupport_FeatureDescription3}`,
      icon: "/icons/hsm_practice_facilities_white.png"
    },
    {
      title: `${hsmOfflinePage.HSMOfflinePage_StudentSupport_FeatureTitle4}`,
      className: "HSM_TabIndivsFeedback",
      content: `${hsmOfflinePage.HSMOfflinePage_StudentSupport_FeatureDescription4}`,
      icon: "/icons/hsm_individualized_feedback_white.png"
    },
    {
      title: `${hsmOfflinePage.HSMOfflinePage_StudentSupport_FeatureTitle5}`,
      className: "HSM_TabCareerGuide",
      content: `${hsmOfflinePage.HSMOfflinePage_StudentSupport_FeatureDescription5}`,
      icon: "/icons/hsm_career_guidance_white.png"
    },
    {
      title: `${hsmOfflinePage.HSMOfflinePage_StudentSupport_FeatureTitle6}`,
      className: "HSM_TabAccessResource",
      content: `${hsmOfflinePage.HSMOfflinePage_StudentSupport_FeatureDescription6}`,
      icon: "/icons/hsm_access_to_resources_white.png"
    },
    {
      title: `${hsmOfflinePage.HSMOfflinePage_StudentSupport_FeatureTitle7}`,
      className: "HSM_TabPerformCoaching",
      content: `${hsmOfflinePage.HSMOfflinePage_StudentSupport_FeatureDescription7}`,
      icon: "/icons/hsm_performance_coaching_white.png"
    },
    {
      title: `${hsmOfflinePage.HSMOfflinePage_StudentSupport_FeatureTitle8}`,
      className: "HSM_TabTechSupport",
      content: `${hsmOfflinePage.HSMOfflinePage_StudentSupport_FeatureDescription8}`,
      icon: "/icons/hsm_technical_support_white.png"
    },
    {
      title: `${hsmOfflinePage.HSMOfflinePage_StudentSupport_FeatureTitle9}`,
      className: "HSM_TabCommuEngagement",
      content: `${hsmOfflinePage.HSMOfflinePage_StudentSupport_FeatureDescription9}`,
      icon: "/icons/hsm_community_engagement_white.png"
    },
    {
      title: `${hsmOfflinePage.HSMOfflinePage_StudentSupport_FeatureTitle10}`,
      className: "HSM_TabMentalWellness",
      content: `${hsmOfflinePage.HSMOfflinePage_StudentSupport_FeatureDescription10}`,
      icon: "/icons/hsm_mental_health_wellness_resources_white.png"
    }
  ];

  const leftTabs = tabContent.slice(0, Math.ceil(tabContent.length / 2));
  const rightTabs = tabContent.slice(Math.ceil(tabContent.length / 2));

  return (
    <>
      <div className="HSM_WCU_Tabs">
        <div className="HSM_WCU_TabButtons HSM_WCU_TabButtonsLeft wow fadeInUp">
        {leftTabs.map((tab, index) => (
            <div
              key={index}
              className={`HSM_WCU_TabsInner ${tab.className} ${
                activeTab === index ? "active" : ""
              }`}
              onClick={() => handleTabClick(index)}
            >
              <p>
                <span>{tab.title}</span>
              </p>
            </div>
          ))}
        </div>
        <div className="HSM_WCU_TabContent wow fadeInUp">
          {tabContent.map((tab, index) => (
            <div
              key={index}
              className={`HSM_CoursesCards ${tab.className} ${
                activeTab === index ? "active" : ""
              }`}
            >
              <div className="HSM_CoursesCardsIcon">
                <img
                  src={`${fileUrl}/${tab.icon}`}
                  alt="why-choose-us-icons"
                  width={80}
                  height={80}
                  objectFit="contain"
                />
              </div>
              <ul>
                  <li>{tab.content}</li>
              </ul>
            </div>
          ))}
        </div>
        <div className="HSM_WCU_TabButtons HSM_WCU_TabButtonsRight wow fadeInUp">
          {rightTabs.map((tab, index) => (
            <div
              key={index}
              className={`HSM_WCU_TabsInner ${tab.className} ${
                activeTab === index + leftTabs.length ? "active" : ""
              }`}
              onClick={() => handleTabClick(index + leftTabs.length)}
            >
              <p>
                <span>{tab.title}</span>
              </p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default StudentSupportTabs;
