import React, { Component } from "react";
import Slider from "react-slick";

const fileUrl = "https://www.harshitamusicacademy.com/files";

export default class WhyChooseHSMSlider extends Component {
  render() {
    const { hsmOfflinePage } = this.props;
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      pauseOnHover: true,
      className: "rv_slider",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        }
      ]
    };

    const boxes = [
        { 
            heading: `${hsmOfflinePage.HSMOfflinePage_WhyChooseHSMOffline_CardTitle1}`,
            icon: "comprehensive_curriculum.png",
            description: `${hsmOfflinePage.HSMOfflinePage_WhyChooseHSMOffline_CardDescription1}`,
        },
        { 
            heading: `${hsmOfflinePage.HSMOfflinePage_WhyChooseHSMOffline_CardTitle2}`,
            icon: "expert_faculty.png",
            description: `${hsmOfflinePage.HSMOfflinePage_WhyChooseHSMOffline_CardDescription2}`,
        },
        { 
            heading: `${hsmOfflinePage.HSMOfflinePage_WhyChooseHSMOffline_CardTitle3}`,
            icon: "art_facilities.png",
            description: `${hsmOfflinePage.HSMOfflinePage_WhyChooseHSMOffline_CardDescription3}`,
        },
        { 
            heading: `${hsmOfflinePage.HSMOfflinePage_WhyChooseHSMOffline_CardTitle4}`,
            icon: "personalized_attention.png",
            description: `${hsmOfflinePage.HSMOfflinePage_WhyChooseHSMOffline_CardDescription4}`,
        },
        { 
            heading: `${hsmOfflinePage.HSMOfflinePage_WhyChooseHSMOffline_CardTitle5}`,
            icon: "music_industry_insights_black.png",
            description: `${hsmOfflinePage.HSMOfflinePage_WhyChooseHSMOffline_CardDescription5}`,
        },
        { 
            heading: `${hsmOfflinePage.HSMOfflinePage_WhyChooseHSMOffline_CardTitle6}`,
            icon: "networking_opportunities.png",
            description: `${hsmOfflinePage.HSMOfflinePage_WhyChooseHSMOffline_CardDescription6}`,
        }
    ];

  return (
    <>
    <ul>
    <Slider {...settings}>
        {boxes.map((box, index) => (
            <div className="HSM_MasteryCourseBox wow fadeInUp" key={index}>
                <div className="HSM_MasteryCourseBoxCard">
                    <h6>{box.heading}</h6>
                    <div className="HSM_MasteryCourseBoxIcon">
                        <img 
                        src={`${fileUrl}/icons/${box.icon}`}
                        alt={box.heading}
                        width={70}
                        height={70}
                        objectFit="contain" />
                    </div>
                    <p>{box.description}</p>
                </div>
            </div>
        ))}
    </Slider>
    </ul>
    </>
   );
  }
}
