import React, {useEffect, useState} from 'react';
import axios from 'axios';
import ReactWhatsapp from 'react-whatsapp';

const WhatsApp = () => {

    const [whatsappNum, setWhatsappNum] = useState([]);

    useEffect(()=>{
        axios.get("https://www.harshitamusicacademy.com/adminPanel/API/contact.php").then((response)=>{
            setWhatsappNum(response.data.Contact);
      })
    },[]);

    return(
        <>
        <li className="wow fadeInUp">
        {whatsappNum.map((res)=>
        <>
            <ReactWhatsapp number={res.HSMContact_WpNumber} message="">
                <i className="fa fa-whatsapp WhatsAppIcon"></i>
            </ReactWhatsapp>
        </>
        )}
        </li>
        </>
    );
}

export default WhatsApp;