import React, {useState, useEffect} from 'react';
import CountUp from "react-countup";
import axios from 'axios';

const StudentsCounter = () => {

    const[studentCounter,setStudentCounter]= useState([]);
  
    useEffect(()=>{
        axios.get("https://www.harshitamusicacademy.com/adminPanel/API/student-counter.php").then((response)=>{
            setStudentCounter(response.data.StudentCounter);
    })
    },[]);

  return (
    <>
    <div className="HSM_CoursePartiular HSM_Tuitions HSM_CoursesSlider">
        <div className="container">
            <div className="HSM_MasteryCourseListing">
                <div className="row HSM_TuitionsAln">
                    {studentCounter.map((studentCounter, index) => (
                    <div className="HSM_TuitionsCols wow fadeInUp col-4" key={index}>
                        <div className="HSM_TuitionsColsInner">
                            <div className="HSM_CoursesCards">
                                <h6>{`${studentCounter.StudentCounter_Title}`}</h6>
                                <h2><CountUp end={`${studentCounter.StudentCounter_CountNum}`} duration={4} />+</h2>
                            </div>
                        </div>
                    </div>
                    ))}
                </div>
            </div>
        </div>
    </div>
    </>
  );
};

export default StudentsCounter;
