import React, { useState } from "react";

const fileUrl = "https://www.harshitamusicacademy.com/files";

const WhyChooseUsSlider = ({ homePage }) => {
  
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const tabContent = [
    {
      title: `${homePage.HomePage_WhyChooseUs_TabTitle1}`,
      className: "HSM_TabExpFaculty",
      content: `${homePage.HomePage_WhyChooseUs_TabDescription1}`,
      icon: "/icons/expert_faculty_white.png"
    },
    {
      title: `${homePage.HomePage_WhyChooseUs_TabTitle2}`,
      className: "HSM_TabComCurriculum",
      content: `${homePage.HomePage_WhyChooseUs_TabDescription2}`,
      icon: "/icons/comprehensive_curriculum_white.png"
    },
    {
      title: `${homePage.HomePage_WhyChooseUs_TabTitle3}`,
      className: "HSM_TabStateFacilities",
      content: `${homePage.HomePage_WhyChooseUs_TabDescription3}`,
      icon: "/icons/art_facilities_white.png"
    },
    {
      title: `${homePage.HomePage_WhyChooseUs_TabTitle4}`,
      className: "HSM_TabFlearningOptions",
      content: `${homePage.HomePage_WhyChooseUs_TabDescription4}`,
      icon: "/icons/flexible_learning_options_white.png"
    },
    {
      title: `${homePage.HomePage_WhyChooseUs_TabTitle5}`,
      className: "HSM_TaPerformOpportunities",
      content: `${homePage.HomePage_WhyChooseUs_TabDescription5}`,
      icon: "/icons/performance_opportunities_white.png"
    },
    {
      title: `${homePage.HomePage_WhyChooseUs_TabTitle6}`,
      className: "HSM_TabExtStuSupport",
      content: `${homePage.HomePage_WhyChooseUs_TabDescription6}`,
      icon: "/icons/extensive_student_support_white.png"
    },
    {
      title: `${homePage.HomePage_WhyChooseUs_TabTitle7}`,
      className: "HSM_TabCertiRecognition",
      content: `${homePage.HomePage_WhyChooseUs_TabDescription7}`,
      icon: "/icons/certification_recognition_white.png"
    },
    {
      title: `${homePage.HomePage_WhyChooseUs_TabTitle8}`,
      className: "HSM_TabLearnCelebs",
      content: `${homePage.HomePage_WhyChooseUs_TabDescription8}`,
      icon: "/icons/learn_from_celebs_white.png"
    }
  ];

  const leftTabs = tabContent.slice(0, Math.ceil(tabContent.length / 2));
  const rightTabs = tabContent.slice(Math.ceil(tabContent.length / 2));

  return (
    <>
      <div className="HSM_WCU_Tabs">
        <div className="HSM_WCU_TabButtons HSM_WCU_TabButtonsLeft wow fadeInUp">
        {leftTabs.map((tab, index) => (
            <div
              key={index}
              className={`HSM_WCU_TabsInner ${tab.className} ${
                activeTab === index ? "active" : ""
              }`}
              onClick={() => handleTabClick(index)}
            >
              <p>
                <span>{tab.title}</span>
              </p>
            </div>
          ))}
        </div>
        <div className="HSM_WCU_TabContent wow fadeInUp">
          {tabContent.map((tab, index) => (
            <div
              key={index}
              className={`HSM_CoursesCards ${tab.className} ${
                activeTab === index ? "active" : ""
              }`}
            >
              <div className="HSM_CoursesCardsIcon">
                <img
                  src={`${fileUrl}/${tab.icon}`}
                  alt="why-choose-us-icons"
                  width={60}
                  height={60}
                  objectFit="contain"
                />
              </div>
              <ul>
                  <li>{tab.content}</li>
              </ul>
            </div>
          ))}
        </div>
        <div className="HSM_WCU_TabButtons HSM_WCU_TabButtonsRight wow fadeInUp">
          {rightTabs.map((tab, index) => (
            <div
              key={index}
              className={`HSM_WCU_TabsInner ${tab.className} ${
                activeTab === index + leftTabs.length ? "active" : ""
              }`}
              onClick={() => handleTabClick(index + leftTabs.length)}
            >
              <p>
                <span>{tab.title}</span>
              </p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default WhyChooseUsSlider;
