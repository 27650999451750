import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Grouped = ({ courseCards }) => {

  const filteredCourseCards = courseCards.filter(
    card => card.AllCoursesCards_Card_CourseLevel === "Preparatory" && card.AllCoursesCards_Card_CourseType === "Group"
  );

  const courseFinalCard = filteredCourseCards[0];

  const [countdown, setCountdown] = useState("");
  const [startCourseTime, setStartCourseTime] = useState("");

  useEffect(() => {
    if (courseFinalCard) {
      setStartCourseTime(courseFinalCard.AllCoursesCards_Card_CourseCardStartFrom);
    }
  }, [courseFinalCard]);

  useEffect(() => {
    if (!startCourseTime) return;

    const targetDate = new Date(`${startCourseTime}T00:00:00`).getTime();

    const updateCountdown = () => {
      const now = new Date().getTime();
      const distance = targetDate - now;

      if (distance < 0) {
        setCountdown("New Batch has started!");
        clearInterval(interval);
        return;
      }

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));

      setCountdown(
        <p>
          <span className="Days">{days}</span> Days{" "}
          <span className="Hours">{hours}</span> Hours{" "}
          <span className="Minutes">{minutes}</span> Minutes{" "}
        </p>
      );
    };

    const interval = setInterval(updateCountdown, 1000);

    return () => clearInterval(interval);
  }, [startCourseTime]);

  return (
    <>
    {filteredCourseCards.map((res) => (
      <>
        <h6 className="wow fadeInUp">Group</h6>
        <p className="HSM_groupCourse wow fadeInUp">{courseFinalCard.AllCoursesCards_Card_CourseCardDescription}</p>

        <div className="row HSM_Course_paricularSelectedCardAln">
          <div className="HSM_Course_paricularSelectedCardcols wow fadeInUp col-12">
            <div className="HSM_Course_paricularSelectedCardcolsInner">
              <h6>Features</h6>
              <div className="HSM_Course_paricularSelectedCardcolsInnerTable">
                <table>
                  <thead>
                    <tr>
                      <th>Feature</th>
                      <th>Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {courseFinalCard.Features.map((feature, index) => (
                    <tr key={index}>
                      <td>{feature.AllCoursesCards_Features_FeatureTitle}</td>
                      <td>
                        {feature.AllCoursesCards_Features_FeatureValue.toLowerCase() === "yes" ? (
                            <i className="fa fa-check"></i>
                          ) : feature.AllCoursesCards_Features_FeatureValue.toLowerCase() === "no" ? (
                            <i className="fa fa-close"></i>
                          ) : (
                            feature.AllCoursesCards_Features_FeatureValue
                        )}
                      </td>
                    </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="HSM_Course_paricularSelectedCardcolsInnerPay">
                <div className="HSM_Course_paricularSelectedCardcolsInnerPayLeft">
                  {/* <div className="HSM_Course_paricularSelectedCardcolsInnerPayBtn">
                    <a href={courseFinalCard.UsersMedia_MediaUrl} target="_blank" rel="noreferrer">Curriculum</a>
                  </div> */}
                  <div className="HSM_Course_paricularSelectedCardcolsInnerPayBtn">
                    <Link to="/admission-form">Enroll Now <i className="fa fa-angle-right"></i></Link>
                  </div>
                </div>
                <div className="HSM_Course_paricularSelectedCardcolsInnerPayRight">
                  <div className="HSM_Course_paricularSelectedCardcolsInnerPayBtn">
                  Rs.<span className="CoursePrice">{courseFinalCard.AllCoursesCards_Card_CourseCardBasePrice}/-</span><br/><span className="discountedCoursePrice">{courseFinalCard.AllCoursesCards_Card_CourseCardDiscountedPrice}/-</span>
                  <br/>
                  <span className="pricePerClass">Rs. {courseFinalCard.AllCoursesCards_Card_CourseCardChargePerClass} / Per Class</span>
                  </div>
                  <div class="HSM_HeaderDemoBtn">
                    <Link to="/contact" className="ankrBtn">Book A Demo <i className="fa fa-angle-right"></i></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="HSM_CourseGroupAlgn">
          <div className="HSM_CorseGroupTime wow fadeInUp">
            <h6>Next Batch Starts From</h6>
            <p>1st July 2024</p>
            <div className="HSM_CorseGroupTimeCountDown">
              {countdown}
            </div>
          </div>
        </div>
      </>
      ))}
    </>
  );
};

export default Grouped;
