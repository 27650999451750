import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';

const PopularPostsSidebar = () => {

  const[popularblog, setPopularBlog]= useState([]);

  useEffect(()=>{
    axios.get("https://www.harshitamusicacademy.com/adminPanel/API/popular-posts.php").then((response)=>{
        setPopularBlog(response.data.Posts);
    })
  },[]);

  const formatTitleForUrl = (title, id) => {
    const formattedTitle = title.replace(/\s+/g, '-').toLowerCase();
    return `${id}-${formattedTitle}`;
  };

  const formatUnixTimestamp = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  };

  return (
    <>
    <div className='KSM_AllBlogRightInner wow fadeInUp'>
        <h5>Popular Posts</h5>
        <div className="KSM_AllBlogRightCard">
            {popularblog.slice(0,4).map((popularblog, index) => (
            <div className="KSM_AllBlogRightCardInner" key={index}>
                <div className="KSM_AllBlogRightCardInnerImg">
                    <Link
                    to={`/blog/${formatTitleForUrl(popularblog.Posts_Title, popularblog.Posts_ID)}`}>
                    <img 
                    src={`${popularblog.UsersMedia_MediaUrl}`}
                    alt={popularblog.Posts_Title} />
                    </Link>
                </div>
                <div className="KSM_AllBlogRightCardInnerContent">
                    <Link
                    to={`/blog/${formatTitleForUrl(popularblog.Posts_Title, popularblog.Posts_ID)}`}>
                    <h6>{`${popularblog.Posts_Title}`}</h6>
                    </Link>
                    <p><span>{`${formatUnixTimestamp(popularblog.Posts_CreateUnixTime)}`}</span></p>
                </div>
            </div>
            ))}
        </div>
    </div>
    </>
  );
};

export default PopularPostsSidebar;
