import React from "react";

import WhyChooseHSMSlider from './WhyChooseHSMSlider';

const fileUrl = "https://www.harshitamusicacademy.com/files";

const WhyChooseHSM = ({hsmOfflinePage}) => {

  return (
    <>
      <div className="HSM_MasteryCourse HSM_CoursesSlider"
      style={{
        backgroundImage: `url('${fileUrl}/banners/why_choose_us_bg_HSMpage.webp')`,
      }}>
        <div className="container">
          <h2 className="wow fadeInUp">{hsmOfflinePage.HSMOfflinePage_WhyChooseHSMOffline_MainTitle}</h2>
          <p className="wow fadeInUp">{hsmOfflinePage.HSMOfflinePage_WhyChooseHSMOffline_Description}</p>
          <div className="HSM_MasteryCourseListing">
            <div className="HSM_WhyChooseHSM HSM_MasteryCourseBoxAlgn">
              <WhyChooseHSMSlider hsmOfflinePage={hsmOfflinePage} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhyChooseHSM;
