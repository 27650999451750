import React, {useState, useEffect} from 'react';
import axios from 'axios';

const fileUrl = "https://www.harshitamusicacademy.com/files";

const OwnerProfile = () => {

  const[ownerProfile,setOwnerProfile]= useState([]);
  
    useEffect(()=>{
        axios.get("https://harshitamusicacademy.com/adminPanel/API/owner-profile.php").then((response)=>{
          setOwnerProfile(response.data.OwnerProfile);
    })
    },[]);

  return (
    <>
    <div className="HSM_AboutProfileBanner HSM_Faq HSM_CoursesSlider"
      style={{
        backgroundImage: `url('${fileUrl}/banners/owner_profile_bg.webp')`,
      }}>
        <div className="container">
            {ownerProfile.map((res) => (
            <div className="row HSM_AboutProfileBannerAln">
                <div className="HSM_AboutProfileBannerCols wow fadeInUp col-6">
                    <div className="HSM_AboutProfileBannerColsCard">
                        <img 
                        src={`${res.UsersMedia_MediaUrl}`}
                        alt="owner-profile"
                        width={635}
                        height={635} />
                    </div>
                </div>
                <div className="HSM_AboutProfileBannerCols wow fadeInUp col-6">
                    <h2>{`${res.OwnerProfile_Title}`}</h2>
                    <h6>{`${res.OwnerProfile_Name}`} <span className="HSM_AboutProfileSpan1">aka</span><span className="HSM_AboutProfileSpan2">{`${res.OwnerProfile_SubTitle}`}</span></h6>
                    <div className='HSM_AboutProfileBannerCols' dangerouslySetInnerHTML={{ __html: res.OwnerProfile_Description }} />
                </div>
            </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default OwnerProfile;
