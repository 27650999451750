import React from "react";
import AdvantagesSlider from './AdvantagesSlider';

const VirtuosoAdvantages = () => {
  return (
    <>
    <h6>Virtuoso Course</h6>
    <p>Introducing the Virtuoso Course at Harshita School of Music, a highly specialized and personalized program designed to cater to the unique goals and skill levels of each student. Whether you are a beginner, intermediate, advanced, or expert musician, or if you simply wish to master a specific set of topics, this course offers unparalleled customization and flexibility.</p>
    <h5 className="vistuosoCoursAdvantagesTitle">Advantages of the Virtuoso Course</h5>
    <AdvantagesSlider />
    </>
  );
};

export default VirtuosoAdvantages;
