import React from "react";

import TestimonialsSlider from "./TestimonialsSlider";

const Testimonials = () => {
  return (
    <>
      <div className="HSM_Testimonials HSM_CoursesSlider">
        <div className="container">
          <h2 className="wow fadeInUp">Testimonials</h2>
          <div className="HSM_Testimonials_Inner wow fadeInUp">
          <TestimonialsSlider />
          </div>
        </div>
      </div>
    </>
  );
}

export default Testimonials;
