import React from "react";

import { Link } from "react-router-dom";

const fileUrl = "https://www.harshitamusicacademy.com/files";

const HomeTuitions = ({ homePage }) => {

  const features = [
    {
      icon: "modern_classrooms.png",
      text: "Modern Classrooms"
    },
    {
      icon: "practice_rooms.png",
      text: "Practice Rooms"
    },
    {
      icon: "career_development.png",
      text: "Career Development"
    },
    {
      icon: "personalized_attention.png",
      text: "Personalized Attention"
    },
    {
      icon: "comprehensive_curriculum.png",
      text: "Comprehensive Curriculum"
    },
    {
      icon: "workshops_masterclasses.png",
      text: "Workshops and Masterclasses"
    },
    {
      icon: "academic_resources.png",
      text: "Academic Resources"
    },
    {
      icon: "networking_opportunities.png",
      text: "Networking Opportunities"
    }
  ];

  return (
    <>
      <div className="HSM_Tuitions HSM_CoursesSlider">
        <div className="container">
          <h2 className="wow fadeInUp">{homePage.HomePage_HSMOffline_MainTitle}</h2>
          <h6 className="HSM_Tuitions_Subheading wow fadeInUp">{homePage.HomePage_HSMOffline_SubTitle1}</h6>
          <p className="wow fadeInUp">{homePage.HomePage_HSMOffline_Description1}</p>
          <div className="HSM_MasteryCourseListing">
            <h6 className="HSM_Tuitions_Subheading2 wow fadeInUp">{homePage.HomePage_HSMOffline_SubTitle2}</h6>
            <div className="row HSM_TuitionsAln">
              {features.map((feature, index) => (
                <div className="HSM_TuitionsCols wow fadeInUp col-3" key={index}>
                  <div className="HSM_TuitionsColsInner">
                    <div className="HSM_CoursesCards">
                      <div className="HSM_TuitionsColsInnerIcon">
                        <img
                          src={`${fileUrl}/icons/${feature.icon}`}
                          alt={feature.text}
                          width={50}
                          height={50}
                          objectFit="contain"
                        />
                      </div>
                      <p><span>{feature.text}</span></p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <p>{homePage.HomePage_HSMOffline_Description2}</p>
          <div className="HSM_HeaderDemoBtn wow fadeInUp">
            <Link to="/hsm-offline" className="ankrBtn">Read More</Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeTuitions;
