import React, { useEffect } from 'react';

import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import Contact from './pages/Contact';
import ApplicationAdmForm from './pages/ApplicationAdmForm';
import HomeTuition from './pages/HomeTuition';
import Blog from './pages/Blog';
import FAQ from './pages/FAQ';
import TermsAndConditions from './pages/TermsAndConditions';

// blog single page 
import BlogSingle from './pages/BlogSingle';

// course pages 

import KeyBoard from './pages/KeyBoard';
import WesternVocals from './pages/WesternVocals';
import Opera from './pages/Opera';

// pending to update content

import IndianClassicalVocals from './pages/IndianClassicalVocals';
import BollywoodFilmSinging from './pages/BollywoodFilmSinging';
import PlaybackStageSinging from './pages/PlaybackStageSinging';
import AcousticGuitar from './pages/AcousticGuitar';
import ElectricGuitar from './pages/ElectricGuitar';
import Violin from './pages/Violin';
import Piano from './pages/Piano';
import Drums from './pages/Drums';
import AllInOne from './pages/AllInOne';


import NotFound from './pages/NotFound';

import WOW from 'wow.js';
import 'animate.css';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {

  useEffect(() => {
    const wow = new WOW();
    wow.init();
  }, []);
  
  return (
    <>
    <Router>
      <Routes>
          <Route path="/" element={<Home />} exact />
          <Route path="/about-us" element={<AboutUs />} exact />
          <Route path="/contact" element={<Contact />} exact />
          <Route path="/admission-form" element={<ApplicationAdmForm />} exact />
          <Route path="/hsm-offline" element={<HomeTuition />} exact />
          <Route path="/blog" element={<Blog />} exact />
          <Route path="/faqs" element={<FAQ />} exact />
          <Route path="/terms-conditions" element={<TermsAndConditions />} exact />

          <Route path="/keyboard" element={<KeyBoard />} exact />
          <Route path="/western-vocals" element={<WesternVocals />} exact />
          <Route path="/opera" element={<Opera />} exact />
          <Route path="/indian-classical-singing" element={<IndianClassicalVocals />} exact />
          <Route path="/bollywood-singing" element={<BollywoodFilmSinging />} exact />
          <Route path="/playback-singing" element={<PlaybackStageSinging />} exact />
          <Route path="/acoustic-guitar" element={<AcousticGuitar />} exact />
          <Route path="/electric-guitar" element={<ElectricGuitar />} exact />
          <Route path="/violin" element={<Violin />} exact />
          <Route path="/piano" element={<Piano />} exact />
          <Route path="/drums" element={<Drums />} exact />
          <Route path="/all-in-one" element={<AllInOne />} exact />

          <Route path="/blog/:Posts_ID" element={<BlogSingle />} exact />

          <Route path="/*" element={<NotFound />} exact />
      </Routes>
    </Router>
    </>
  );
}

export default App;
