import React, {useState, useEffect} from 'react';
import { NavLink, Link } from "react-router-dom";
import axios from 'axios';

import SocialMedia from "./SocialMedia";

const fileUrl = "https://www.harshitamusicacademy.com/files";

const Footer = () => {

  const currentYear = new Date().getFullYear();

  const[contact,setContact]= useState([]);
  
  useEffect(()=>{
    axios.get("https://www.harshitamusicacademy.com/adminPanel/API/contact.php").then((response)=>{
      setContact(response.data.Contact);
  })
  },[]);

  return (
    <>
      <div className="HSM_Footer">
        <div className="container">
          <div className="row HSM_FooterAln">
            <div className="HSM_FooterCols wow fadeInUp col-2">
              <h6>Quick Links</h6>
              <ul>
                <li>
                  <NavLink to="/blog" activeClassName="active">Blog</NavLink>
                </li>
                <li>
                  <NavLink to="/admission-form" activeClassName="active">Admission Form</NavLink>
                </li>
                <li>
                  <NavLink to="/hsm-offline" activeClassName="active">HSM Offline</NavLink>
                </li>
                <li>
                  <NavLink to="/contact" activeClassName="active">Boook A Demo</NavLink>
                </li>
                <li>
                  <NavLink to="/contact" activeClassName="active">Contact Us</NavLink>
                </li>
              </ul>
            </div>
            <div className="HSM_FooterCols wow fadeInUp col-4">
              <h6>Courses</h6>
              <ul className="HSM_FooterColsCourses">
                <li>
                  <NavLink to="/keyboard" activeClassName="active">Keyboard</NavLink>
                </li>
                <li>
                  <NavLink to="/western-vocals" activeClassName="active">Western Vocals</NavLink>
                </li>
                <li>
                  <NavLink to="/opera" activeClassName="active">Opera</NavLink>
                </li>
                <li>
                  <NavLink to="/indian-classical-singing" activeClassName="active">Indian Classical Vocals</NavLink>
                </li>
                <li>
                  <NavLink to="/bollywood-singing" activeClassName="active">Bollywood Film Singing</NavLink>
                </li>
                <li>
                  <NavLink to="/playback-singing" activeClassName="active">Playback Stage Singing</NavLink>
                </li>

                <li>
                  <NavLink to="/acoustic-guitar" activeClassName="active">Acoustic Guitar</NavLink>
                </li>
                <li>
                  <NavLink to="/electric-guitar" activeClassName="active">Electric Guitar</NavLink>
                </li>
                <li>
                  <NavLink to="/violin" activeClassName="active">Violin</NavLink>
                </li>
                <li>
                  <NavLink to="/piano" activeClassName="active">Piano</NavLink>
                </li>
                <li>
                  <NavLink to="/drums" activeClassName="active">Drums</NavLink>
                </li>
                <li>
                  <NavLink to="/all-in-one" activeClassName="active">All-In-One Course</NavLink>
                </li>
              </ul>
            </div>
            <div className="HSM_FooterCols wow fadeInUp col-3">
              <h6>Support</h6>
              {contact.map((res, index) => (
              <ul key={index}>
                <li>
                  <i className="fa fa-envelope-o"></i>
                  <a href={`mailto: ${res.HSMContact_EmailPrimary}`} target="_blank" rel="noreferrer">{res.HSMContact_EmailPrimary}</a>
                </li>
                <li>
                  <i className="fa fa-envelope-o"></i>
                  <a href={`mailto: ${res.HSMContact_EmailSecondary}`} target="_blank" rel="noreferrer">{res.HSMContact_EmailSecondary}</a>
                </li>
                <li>
                  <i className="fa fa-envelope-o"></i>
                  <a href={`mailto: ${res.HSMContact_FounderEmail}`} target="_blank" rel="noreferrer">{res.HSMContact_FounderEmail}</a>
                </li>
                <li>
                  <i className="fa fa-phone"></i>
                  <a href={`tel: ${res.HSMContact_ContactNumberPrimary}`} target="_blank" rel="noreferrer">{res.HSMContact_ContactNumberPrimary}</a>
                </li>
                <li>
                  <i className="fa fa-phone"></i>
                  <a href={`tel: ${res.HSMContact_ContactNumberSecondary}`} target="_blank" rel="noreferrer">{res.HSMContact_ContactNumberSecondary}</a>
                </li>
                <li>
                  <NavLink to="/terms-conditions" activeClassName="active">Terms & Conditions</NavLink>
                </li>
                <li>
                  <NavLink to="/faqs" activeClassName="active">FAQ</NavLink>
                </li>
              </ul>
              ))}
            </div>
            <div className="HSM_FooterCols SocialMedia wow fadeInUp col-3">
              <div className="HSM_FooterSocialMedia">
                <h6>Social Media</h6>
                <p>Follow us on social media to stay updated with the latest news, events, and musical inspiration</p>
                <ul>
                  <SocialMedia />
                </ul>
              </div>
            </div>
          </div>
          <div className="HSM_FooterBtm">
            <div className="HSM_FooterBtmLogo">
              <Link to="/">
                <img
                  src={`${fileUrl}/hsm_logo.png`}
                  alt="HSM-Logo"
                />
              </Link>
            </div>
            <p className="wow fadeInUp">Harshita School Of Music Academy © {currentYear}. All Rights Reserved.</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
