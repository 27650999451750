import React, {useState, useEffect} from 'react';
import axios from 'axios';

import Header from "../components/Header";
import Footer from "../components/Footer";

import MetaTags from "../components/MetaTags";

import About from '../components/about/About';
import Profile from '../components/about/Profile';

import Testimonials from "../components/home/Testimonials";
import LatestBlog from "../components/home/LatestBlog";
import ContactUs from "../components/home/ContactUs";
import BookDemo from "../components/home/BookDemo";
import FAQ from "../components/home/FAQ";

import StudentsCounter from "../components/StudentsCounter";
import EnrollNowButton from "../components/EnrollNowButton";

import InstaPosts from '../components/InstaPosts';

export default function AboutPage() {

  const[seo, setSeo]= useState([]);
  
  useEffect(()=>{
    axios.get("https://www.harshitamusicacademy.com/adminPanel/API/seo.php").then((response)=>{
      setSeo(response.data.SEO);
  })
  },[]);

  const[aboutPage, setAboutPage]= useState([]);
  
  useEffect(()=>{
    axios.get("https://www.harshitamusicacademy.com/adminPanel/API/about-us-page.php").then((response)=>{
      setAboutPage(response.data.AboutUsPage[0]);
  })
  },[]);
  
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant"
    });
  }, []);

  return (
    <>
    {seo.map((res) => (
      <MetaTags
      title={`${res.SEO_Title_About}`}
      description={`${res.SEO_Description_About}`}
      keywords={`${res.SEO_Keyword_About}`} />
    ))}

    <Header />

    <EnrollNowButton />

    <About />

    <StudentsCounter />

    <Profile aboutPage={aboutPage} />

    <Testimonials />

    <LatestBlog />

    <ContactUs />

    <InstaPosts />

    <BookDemo />

    <FAQ />

    <Footer />
    </>
  );
}
