import React from 'react';
import { Helmet } from 'react-helmet';

const MetaTags = ({ title, description, keywords }) => (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      {<meta name="keywords" content={keywords} />}
      {<meta name="author" content="Harshita Rewadia" />}
      <link rel="canonical" href={window.location.href} />


      {/* Open Graph Meta Tags */}
      {<meta property="og:title" content={title} />}
      {<meta property="og:description" content={description} />}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={window.location.href} />
      {<meta property="og:image" content="https://www.harshitamusicacademy.com/files/hsm_logo.png" />}


      {/* Twitter Card Meta Tags */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:url" content={window.location.href} />
      {<meta name="twitter:image" content="https://www.harshitamusicacademy.com/files/logo.webp" />}

    </Helmet>
  );
  
  export default MetaTags;