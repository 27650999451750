import React,{ useEffect } from 'react';

import Header from "../components/Header";
import Footer from "../components/Footer";

import EnrollNowButton from "../components/EnrollNowButton";

import ErrorPage from '../components/ErrorPage'

export default function Custom404() {

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant"
        });
      }, []);
      
    return (
        <>
        <EnrollNowButton />
        
        <Header />

        <ErrorPage/>

        <Footer />
        </>
    );
}