import React, {useState, useEffect} from 'react';
import axios from 'axios';

const fileUrl = "https://www.harshitamusicacademy.com/files";


const TermsAndConditions = () => {

  const[terms, setTerms]= useState([]);
  
    useEffect(()=>{
        axios.get("https://www.harshitamusicacademy.com/adminPanel/API/terms-conditions.php").then((response)=>{
          setTerms(response.data.TermsConditions);
    })
    },[]);

  return (
    <>
      <div className="KSM_Banner"
      style={{
        backgroundImage: `url('${fileUrl}/banners/Terms_conditions_banner_page.webp')`,
      }}>
        <div className="container">
          <h1 className="wow fadeInUp">Terms & Conditions</h1>
        </div>
      </div>

      <div className="KSM_TermsConditionsContent">
        <div className="container">
        {terms.map((res) => (
          <div className="KSM_TermsConditionsMain" dangerouslySetInnerHTML={{ __html: res.TermsConditions_Content }} />
        ))}
        </div>
      </div>
    </>
  );
};

export default TermsAndConditions;
