import React, { Component } from "react";
import Slider from "react-slick";

import BannerOverlayContent from "./BannerOverlayContent";

const fileUrl = "https://www.harshitamusicacademy.com/files";

export default class HomeBannerSlider extends Component {
  render() {
    const settings = {
      dots: false,
      fade: true,
      arrows: true,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      className: "HomeBanner_Slider",
      autoplay: false,
      autoplaySpeed: 7000,
      pauseOnHover: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            fade: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
            arrows: true
          }
        },
        {
          breakpoint: 768,
          settings: {
            fade: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
            arrows: true,
          }
        },
        {
          breakpoint: 480,
          settings: {
            fade: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
            arrows: true,
          }
        }
      ]
    };

    const banners = [
      { image: "home_slide1.webp", title: "Discover the Joy of Music" },
      { image: "home_slide2.webp", title: "Learn & Perform" },
      { image: "home_slide3.webp", title: "Discover Your Sound" },
      { image: "home_slide4.webp", title: "Unleash Your Talent" },
      { image: "home_slide5.webp", title: "Join the Melody" },
      { image: "home_slide6.webp", title: "Play with Passion" },
      { image: "home_slide7.webp", title: "Elevate Your Skills" },
      { image: "home_slide8.webp", title: "Where Music Thrives" }
    ];

  return (
    <>
    <Slider {...settings}>
      {banners.map((banner, index) => (
        <div className="KSM_Banner" key={index}>
          <img 
            src={`${fileUrl}/banners/${banner.image}`}
            alt="home-banner" />
          <div className="container">
            <h1 className="wow fadeInUp">{banner.title}</h1>
            <BannerOverlayContent />
          </div>
        </div>
      ))}
    </Slider>
    </>
   );
  }
}
