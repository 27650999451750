import React from 'react';
import ReactDOM from 'react-dom/client';

import './css/font-awesome.min.css';
import './css/slick.css';
import './css/slick-theme.css';
import './css/animate.css';
import './css/global.css';
import './css/style.css'; 
import './css/style-responsive.css';

import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
reportWebVitals();
