import React, { Component } from "react";
import Slider from "react-slick";

export default class AdvantagesSlider extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      pauseOnHover: true,
      className: "rv_slider",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        }
      ]
    };

  return (
    <>
    <ul className="virtuosoAdvantagesListing">
    <Slider {...settings}>
        <li><span className="HSM_Course_particularListTitle">Personalized Curriculum</span><p>Each student receives a tailor-made curriculum created by our expert instructors, ensuring that your learning path aligns perfectly with your musical goals and current skill level.</p></li>
        <li><span className="HSM_Course_particularListTitle">Solo Classes</span><p>Enjoy one-on-one instruction that allows for focused, individualized attention, enabling you to progress at your own pace and delve deeply into the nuances of your chosen subject.</p></li>
        <li><span className="HSM_Course_particularListTitle">Progress Tracking</span><p>The course includes a comprehensive personal track record, which details everything you have been taught and what you have learned. This feature allows students and parents to monitor progress closely, ensuring transparency and accountability in the learning process.</p></li>
        <li><span className="HSM_Course_particularListTitle">Flexible Learning Path</span><p>Start learning at any level and seamlessly transition through different stages of proficiency. Whether you are just starting out or refining your expertise, the Virtuoso Course adapts to your needs.</p></li>
        <li><span className="HSM_Course_particularListTitle">Expert Instructors</span><p>Learn from some of the best musicians and educators in the industry, gaining insights and techniques that are typically reserved for advanced professionals.</p></li>
        <li><span className="HSM_Course_particularListTitle">Exclusive Resources</span><p>Gain access to premium learning materials, specialized exercises, and advanced repertoire tailored to your personal learning objectives.</p></li>
        <li><span className="HSM_Course_particularListTitle">Performance Opportunities</span><p>Participate in exclusive recitals and masterclasses, providing you with valuable stage experience and exposure.</p></li>
        <li><span className="HSM_Course_particularListTitle">Holistic Development</span><p>Alongside technical skills, our instructors focus on developing your musical expression, creativity, and performance confidence.</p></li>
        <li><span className="HSM_Course_particularListTitle">Topic-Based Learning</span><p>Focus on specific areas of interest or particular skills you wish to master, ensuring a targeted and efficient learning experience.</p></li>
        <li><span className="HSM_Course_particularListTitle">Comprehensive Feedback</span><p>Receive detailed feedback after each session to guide your practice and development, ensuring continuous improvement.</p></li>
    </Slider>
    </ul>
    </>
   );
  }
}
