import React from "react";

const fileUrl = "https://www.harshitamusicacademy.com/files";

const FAQBanner = () => {
  return (
    <>
      <div className="HSM_FAQ_Banner KSM_Banner"
      style={{
        backgroundImage: `url('${fileUrl}/banners/faqs_bg.png')`,
      }}>
        <div className="container">
          <h1 className="wow fadeInUp">Faq's</h1>
        </div>
      </div>
    </>
  );
};

export default FAQBanner;
