
import React, { useState, useEffect, useRef } from "react";

import { NavLink, Link } from "react-router-dom";

import ScrollTop from "./ScrollTop";
import ContactFixed from "./ContactFixed";
import WhatsApp from "./WhatsApp";

const fileUrl = "https://www.harshitamusicacademy.com/files";

const Header = () => {

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMenuOpenMobile, setIsMenuOpenMobile] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const coursesMenuRef = useRef(null);
  const coursesMenuRefMobile = useRef(null);

  const handleClickOutside = (event) => {
    if (coursesMenuRef.current && !coursesMenuRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  const handleClickOutside2 = (event) => {
    if (coursesMenuRefMobile.current && !coursesMenuRefMobile.current.contains(event.target)) {
      setIsMenuOpenMobile(false);
    }
  };

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuOpen]);

  useEffect(() => {
    if (isMenuOpenMobile) {
      document.addEventListener("mousedown", handleClickOutside2);
    } else {
      document.removeEventListener("mousedown", handleClickOutside2);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside2);
    };
  }, [isMenuOpenMobile]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.classList.add("mobileMenuActive");
    } else {
      document.body.classList.remove("mobileMenuActive");
    }
  }, [isMobileMenuOpen]);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prev) => !prev);
  };
  
  return (
    <>
      <ScrollTop />

      <ContactFixed />
      
      <div className="FixedWhatsAppButton">
        <WhatsApp />
      </div>

      <div className={`HSM_Header ${isSticky ? "sticky" : ""}`}>
        <div className="container">
          <div className="row HSM_HdrAln">
            <div className="HSM_HeaderLeft col-2">
              <Link to="/">
                <img
                  src={`${fileUrl}/hsm_logo.png`}
                  alt="HSM-Logo"
                />
              </Link>
            </div>
            <div className="HSM_HeaderMenus col-8">
              <ul>
                <li>
                  <NavLink to="/" activeClassName="active">Home</NavLink>
                </li>
                <li className="HSM_HeaderCourses"
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                ref={coursesMenuRef}>
                  <button type="button">Courses <i className="fa fa-angle-down"></i></button>
                  <div className={`HSM_HeaderCoursesContent ${isMenuOpen ? "openMenu" : ""}`}>
                    <div className="container">
                      <div className="row HSM_HeaderCoursesContentAln">
                        <div className="HSM_HeaderCoursesContentCols col-5-eq">
                          <h6>Vocals</h6>
                          <ul>
                            <li>
                              <NavLink to="/opera" activeClassName="active">Opera</NavLink>
                            </li>
                            <li>
                              <NavLink to="/western-vocals" activeClassName="active">Western Vocals</NavLink>
                            </li>
                            <li>
                              <NavLink to="/indian-classical-singing" activeClassName="active">Indian Classical Vocals</NavLink>
                            </li>
                            <li>
                              <NavLink to="/bollywood-singing" activeClassName="active">Bollywood Film Singing</NavLink>
                            </li>
                            <li>
                              <NavLink to="/playback-singing" activeClassName="active">Playback Stage Singing</NavLink>
                            </li>
                          </ul>
                        </div>
                        <div className="HSM_HeaderCoursesContentCols col-5-eq">
                          <h6>STRINGS</h6>
                          <ul>
                            <li>
                              <NavLink to="/acoustic-guitar" activeClassName="active">Acoustic Guitar</NavLink>
                            </li>
                            <li>
                              <NavLink to="/electric-guitar" activeClassName="active">Electric Guitar</NavLink>
                            </li>
                            <li>
                              <NavLink to="/violin" activeClassName="active">Violin</NavLink>
                            </li>
                          </ul>
                        </div>
                        <div className="HSM_HeaderCoursesContentCols col-5-eq">
                          <h6>KEYS</h6>
                          <ul>
                            <li>
                              <NavLink to="/keyboard" activeClassName="active">Keyboard</NavLink>
                            </li>
                            <li>
                              <NavLink to="/piano" activeClassName="active">Piano</NavLink>
                            </li>
                          </ul>
                        </div>
                        <div className="HSM_HeaderCoursesContentCols col-5-eq">
                          <h6>PERCUSSION</h6>
                          <ul>
                            <li>
                              <NavLink to="/drums" activeClassName="active">Drums</NavLink>
                            </li>
                          </ul>
                        </div>
                        <div className="HSM_HeaderCoursesContentCols col-5-eq">
                          <h6>ALL IN ONE COURSE</h6>
                          <ul>
                            <li>
                              <NavLink to="/all-in-one" activeClassName="active">ALL IN ONE COURSE</NavLink>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <NavLink to="/hsm-offline" activeClassName="active">HSM Offline</NavLink>
                </li>
                <li>
                  <NavLink to="/about-us" activeClassName="active">About Us</NavLink>
                </li>
                <li>
                  <NavLink to="/admission-form" activeClassName="active">Admission Form</NavLink>
                </li>
                <li>
                  <NavLink to="/blog" activeClassName="active">Blog</NavLink>
                </li>
                <li>
                  <NavLink to="/contact" activeClassName="active">Contact</NavLink>
                </li>
              </ul>
            </div>
            <div className="HSM_HeaderRight col-2">
              <div className="HSM_HeaderDemoBtn">
                <Link to="/contact" className="ankrBtn">Book A Demo</Link>
              </div>
            </div>
            <div className="HSM_HeaderMenusMobile col-8">
              <span className="bars" onClick={toggleMobileMenu}>
                <div className="bar1"></div>
                <div className="bar2"></div>
                <div className='bar3'></div>
              </span>
            </div>

            <div
              className={`HSM_HeaderMenusMobileClosed ${
                isMobileMenuOpen ? "Opened" : ""
              }`}
            >
              <div className='HSM_MobileMenuClose'>
                <span className='closebtn' onClick={() => setIsMobileMenuOpen(false)}>&#x2715;</span>
              </div>
              <ul>
                <li>
                  <NavLink to="/" activeClassName="active">Home</NavLink>
                </li>
                <li className="HSM_HeaderCourses"
                onClick={() => setIsMenuOpenMobile(!isMenuOpenMobile)}
                ref={coursesMenuRefMobile}>
                  <button type="button">Courses <i className="fa fa-angle-down"></i></button>
                  <div className={`HSM_HeaderCoursesContent ${isMenuOpenMobile ? "openMenu" : ""}`}>
                    <div className="container">
                      <div className="row HSM_HeaderCoursesContentAln">
                        <div className="HSM_HeaderCoursesContentCols col-5-eq">
                          <h6>Vocals</h6>
                          <ul>
                            <li>
                              <NavLink to="/opera" activeClassName="active">Opera</NavLink>
                            </li>
                            <li>
                              <NavLink to="/western-vocals" activeClassName="active">Western Vocals</NavLink>
                            </li>
                            <li>
                              <NavLink to="/indian-classical-singing" activeClassName="active">Indian Classical Vocals</NavLink>
                            </li>
                            <li>
                              <NavLink to="/bollywood-singing" activeClassName="active">Bollywood Film Singing</NavLink>
                            </li>
                            <li>
                              <NavLink to="/playback-singing" activeClassName="active">Playback Stage Singing</NavLink>
                            </li>
                          </ul>
                        </div>
                        <div className="HSM_HeaderCoursesContentCols col-5-eq">
                          <h6>STRINGS</h6>
                          <ul>
                            <li>
                              <NavLink to="/acoustic-guitar" activeClassName="active">Acoustic Guitar</NavLink>
                            </li>
                            <li>
                              <NavLink to="/electric-guitar" activeClassName="active">Electric Guitar</NavLink>
                            </li>
                            <li>
                              <NavLink to="/violin" activeClassName="active">Violin</NavLink>
                            </li>
                          </ul>
                        </div>
                        <div className="HSM_HeaderCoursesContentCols col-5-eq">
                          <h6>KEYS</h6>
                          <ul>
                            <li>
                              <NavLink to="/keyboard" activeClassName="active">Keyboard</NavLink>
                            </li>
                            <li>
                              <NavLink to="/piano" activeClassName="active">Piano</NavLink>
                            </li>
                          </ul>
                        </div>
                        <div className="HSM_HeaderCoursesContentCols col-5-eq">
                          <h6>PERCUSSION</h6>
                          <ul>
                            <li>
                              <NavLink to="/drums" activeClassName="active">Drums</NavLink>
                            </li>
                          </ul>
                        </div>
                        <div className="HSM_HeaderCoursesContentCols col-5-eq">
                          <h6>ALL IN ONE COURSE</h6>
                          <ul>
                            <li>
                              <NavLink to="/all-in-one" activeClassName="active">ALL IN ONE COURSE</NavLink>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <NavLink to="/hsm-offline" activeClassName="active">HSM Offline</NavLink>
                </li>
                <li>
                  <NavLink to="/about-us" activeClassName="active">About Us</NavLink>
                </li>
                <li>
                  <NavLink to="/admission-form" activeClassName="active">Admission Form</NavLink>
                </li>
                <li>
                  <NavLink to="/blog" activeClassName="active">Blog</NavLink>
                </li>
                <li>
                  <NavLink to="/contact" activeClassName="active">Contact</NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
