import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';

import PopularCoursesSidebar from './PopularCoursesSidebar';
import LatestPostsSidebar from './LatestPostsSidebar';
import PopularPostsSidebar from './PopularPostsSidebar';

const fileUrl = "https://www.harshitamusicacademy.com/files";

const Blog = ({blogPage}) => {

  const[blog, setBlog]= useState([]);

  const formatTitleForUrl = (title, id) => {
    const formattedTitle = title.replace(/\s+/g, '-').toLowerCase();
    return `${id}-${formattedTitle}`;
  };

  const[blogcategory, setBlogcategory]= useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All");

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);

  useEffect(() => {
    const fetchPosts = () => {
      const categoryQuery = selectedCategory !== "All" ? `?category=${encodeURIComponent(selectedCategory)}` : '';
      axios.get(`https://www.harshitamusicacademy.com/adminPanel/API/posts.php${categoryQuery}`).then((response) => {
        setBlog(response.data.Posts);
      });
    };
    fetchPosts();
  }, [selectedCategory]);
  
  useEffect(()=>{
    axios.get("https://www.harshitamusicacademy.com/adminPanel/API/posts-categories.php").then((response)=>{
      setBlogcategory(response.data.PostsCategories);
    })
  },[]);

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setCurrentPage(1);
  };

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = blog.slice(indexOfFirstPost, indexOfLastPost);

  const scrollToTop = () => {
    const element = document.querySelector('.KSM_AllBlogMainpara');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    scrollToTop();
  };

  const nextPage = () => {
    scrollToTop();
    setTimeout(() => {
      setCurrentPage((prevPage) => prevPage + 1);
    }, 1000);
  };
  
  const prevPage = () => {
    scrollToTop();
    setTimeout(() => {
      setCurrentPage((prevPage) => prevPage - 1);
    }, 1000);
  };

  return (
    <>
      <div className="KSM_Banner"
      style={{
        backgroundImage: `url('${fileUrl}/banners/blog_page_banner.webp')`,
      }}>
        <div className="container">
          <h1 className="wow fadeInUp">Blog</h1>
        </div>
      </div>

      <div className="KSM_AllBlogMain">
        <div className="container">
          <p className="KSM_AllBlogMainpara wow fadeInUp">{blogPage.BlogPage_Desc1}</p>
          <div className="row KSM_AllBlogAln">
            <div className="KSM_AllBlogLeft col-9">
              {currentPosts.map((blog, index) => (
              <div className="KSM_AllBlgLeftCard wow fadeInUp" key={index}>
                <div className='KSM_AllBlgLeftCardInner'>
                  <div className="KSM_AllBlgLeftCardInnerContent">
                    <h5>{`${blog.Posts_Title}`}</h5>
                    <h6>Category : <span>{`${blog.Posts_CategoryName}`}</span></h6>
                    <p>{`${blog.Posts_ShortDescription}`}</p>
                    <div className="HSM_HeaderDemoBtn">
                      <Link  
                      to={`/blog/${formatTitleForUrl(blog.Posts_Title, blog.Posts_ID)}`} 
                      className="ankrBtn">Read More <i className="fa fa-angle-right"></i></Link>
                    </div>
                  </div>
                  <div className="KSM_AllBlgLeftCardInnerImg">
                    <img 
                    src={`${blog.UsersMedia_MediaUrl}`}
                    alt={`${blog.Posts_CategoryName}`} />
                  </div>
                </div>
              </div>
              ))}

              {blog.length > postsPerPage && (
              <div className="KSM_BlogPagination">
                <ul className="pagination">
                  <li className={`prev-btn ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button onClick={prevPage} className="page-link" disabled={currentPage === 1}>Previous</button>
                  </li>
                  {Array(Math.ceil(blog.length / postsPerPage))
                    .fill()
                    .map((_, index) => (
                      <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                        <button onClick={() => paginate(index + 1)} className="page-link">
                          {index + 1}
                        </button>
                      </li>
                    ))}
                  <li className={`next-btn ${currentPage === Math.ceil(blog.length / postsPerPage) ? 'disabled' : ''}`}>
                    <button onClick={nextPage} className="page-link" disabled={currentPage === Math.ceil(blog.length / postsPerPage)}>Next</button>
                  </li>
                </ul>
              </div>
              )}
            </div>
            <div className="KSM_AllBlogRight col-3">
              <div className='KSM_AllBlogRightInner wow fadeInUp'>
                <h5>Posts by Category</h5>
                <div className="KSM_ALLBlogRightInnerCategory">
                  <form method='POST'>
                    <select onChange={handleCategoryChange}>
                      <option value="All">All</option>
                      {blogcategory.map((category, index) => (
                        <option value={category.PostsCategories_CategoryName} key={index}>
                          {category.PostsCategories_CategoryName}
                        </option>
                      ))}
                    </select>
                  </form>
                </div>
              </div>
              <PopularCoursesSidebar />
              <LatestPostsSidebar />
              <PopularPostsSidebar />
            </div>
          </div>
          <p className="KSM_AllBlogMainparabtm wow fadeInUp">{blogPage.BlogPage_Desc2}</p>
        </div>
      </div>
    </>
  );
};

export default Blog;
