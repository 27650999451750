import React, { useState, useEffect } from "react";
import axios from 'axios';
import OverlayBannerContent from "../OverlayBannerContent";
import AllSoloCardsInstruction from "../AllSoloCardsInstruction";
import PreparatoryCard from "./PreparatoryCard";
import InterMediateCard from "./InterMediateCard";
import AdvancedCard from "./AdvancedCard";
import GroupCourseCard from "./GroupCourseCard";
import VirtuosoCard from "./VirtuosoCard";
import VirtuosoAdvantages from '../VirtuosoAdvantages';
import BookDemo from "../../home/BookDemo";
import ContactUs from "../../home/ContactUs";
import FAQ from "../../home/FAQ";

const fileUrl = "https://www.harshitamusicacademy.com/files";

const Opera = () => {

  const[courseCards, setCourseCards]= useState([]);

  useEffect(()=>{
    axios.get("https://www.harshitamusicacademy.com/adminPanel/API/opera-course.php").then((response)=>{
      setCourseCards(response.data.OperaCourse);
  })
  },[]);

  const [activeClassType, setActiveClassType] = useState(null);
  
  const handleClassTypeClick = (classType) => {
    setActiveClassType(classType);
  };

  return (
    <>
      <div className="KSM_Banner"
      style={{
        backgroundImage: `url('${fileUrl}/banners/opera_banner.webp')`,
      }}>
        <div className="container">
          <h1 className="wow fadeInUp">Opera</h1>
          <OverlayBannerContent />
        </div>
      </div>
      <div className="HSM_Course_Particular HSM_Tuitions HSM_CoursesSlider">
        <div className="container">
          <div className="HSM_Course_particularDesc">
            <div className="HSM_Course_particularDescInner wow fadeInUp">
              <h6>What it Means</h6>
              <p>Opera is a dramatic art form that combines singing, acting, and orchestral music to tell a story. Known for its emotional intensity and technical demands, opera requires powerful vocal ability and expressive performance skills.</p>
            </div>
            <div className="HSM_Course_particularDescInner wow fadeInUp">
              <h6>Training Involves</h6>
              <ul>
                <li>Vocal techniques for operatic singing, focusing on breath control, projection, and resonance.</li>
                <li>Interpretation and performance of classical opera pieces from various eras.</li>
                <li>Language diction and pronunciation for Italian, French, German, and English operas.</li>
                <li>Stage presence and acting skills.</li>
                <li>Sight-reading and aural skills.</li>
              </ul>
            </div>
            <div className="HSM_Course_particularDescInner wow fadeInUp">
              <h6>Other Details</h6>
              <p>Students will perform in recitals and have opportunities to participate in masterclasses with renowned opera singers. Our program is tailored to both beginners and advanced students, fostering growth at every level.</p>
            </div>
          </div>
          <div className="HSM_Course_particularSelect wow fadeInUp">
            <h5>Select Your Class Type</h5>
            <p>(click the buttons below to select)</p>
            <div className="row HSM_Course_particularSelectAln">
              <div
                className={`HSM_Course_particularSelectBtn ${activeClassType === 'solo' ? 'active' : ''}`}
                onClick={() => handleClassTypeClick('solo')}
              >
                <h6>Solo</h6>
              </div>
              <div
                className={`HSM_Course_particularSelectBtn ${activeClassType === 'group' ? 'active' : ''}`}
                onClick={() => handleClassTypeClick('group')}
              >
                <h6>Group</h6>
              </div>
            </div>
          </div>
          <div className="HSM_Course_paricularSelected">
            <div className={`HSM_Course_paricularSelectedSolo ${activeClassType === 'solo' ? 'active' : ''}`}>
              <h5 className="HSM_Course_paricularSelectedSoloTitle wow fadeInUp">Please refer to this section where you find * and **</h5>
              <AllSoloCardsInstruction />
            </div>
          </div>
          <div className="HSM_Course_paricularSelected">
            <div className={`HSM_Course_paricularSelectedSolo ${activeClassType === 'solo' ? 'active' : ''}`}>
              <h6 className="wow fadeInUp">Preparatory</h6>
              <ul className="wow fadeInUp">
                <li><span className="HSM_Course_particularListTitle">What We Teach</span>Fundamental vocal techniques, breath control, introduction to operatic repertoire, basic language diction (Italian, German, French), and posture.</li>
                <li><span className="HSM_Course_particularListTitle">Who Can Enroll</span>Beginners passionate about singing and interested in opera.</li>
                <li><span className="HSM_Course_particularListTitle">Learning Outcomes</span>Mastery of basic opera singing techniques, ability to perform simple arias, and understanding of basic diction in opera languages.</li>
              </ul>
              <PreparatoryCard courseCards={courseCards} />
            </div>
          </div>
          <div className="HSM_Course_paricularSelected">
            <div className={`HSM_Course_paricularSelectedSolo ${activeClassType === 'solo' ? 'active' : ''}`}>
              <h6 className="wow fadeInUp">Intermediate</h6>
              <ul className="wow fadeInUp">
                <li><span className="HSM_Course_particularListTitle">What We Teach</span>Advanced vocal techniques, detailed study of operatic roles, stage presence, acting for singers, intermediate language skills.</li>
                <li><span className="HSM_Course_particularListTitle">Who Can Enroll</span>Students with foundational vocal training or some experience in opera.</li>
                <li><span className="HSM_Course_particularListTitle">Learning Outcomes</span>Enhanced vocal range and control, ability to perform intermediate operatic pieces, improved stage presence and acting skills.</li>
              </ul>
              <InterMediateCard courseCards={courseCards} />
            </div>
          </div>
          <div className="HSM_Course_paricularSelected">
            <div className={`HSM_Course_paricularSelectedSolo ${activeClassType === 'solo' ? 'active' : ''}`}>
              <h6 className="wow fadeInUp">Advanced</h6>
              <ul className="wow fadeInUp">
                <li><span className="HSM_Course_particularListTitle">What We Teach</span>Mastery of complex operatic repertoire, advanced language diction, professional stage performance, audition techniques, character interpretation.</li>
                <li><span className="HSM_Course_particularListTitle">Who Can Enroll</span>Experienced singers aiming to refine their skills for professional opera.</li>
                <li><span className="HSM_Course_particularListTitle">Learning Outcomes</span>Professional-level performance, mastery of operatic roles, readiness for auditions and professional engagements.</li>
              </ul>
              <AdvancedCard courseCards={courseCards} />
            </div>
          </div>

          <div className="HSM_Course_Virtuoso HSM_Course_paricularSelected">
            <div className={`HSM_Course_paricularSelectedSolo ${activeClassType === 'solo' ? 'active' : ''}`}>
              <VirtuosoAdvantages />
              <VirtuosoCard courseCards={courseCards} />
              <p className="vistuosoCourseCardBtm wow fadeInUp">The Virtuoso Course is designed to provide a bespoke learning experience, ensuring each student achieves their highest potential. Join us at Harshita School of Music and embark on a musical journey tailored just for you.</p>
            </div>
          </div>

          <div className="HSM_Course_paricularSelected">
            <div className={`HSM_Course_paricularSelectedGroup ${activeClassType === 'group' ? 'active' : ''}`}>
              <GroupCourseCard courseCards={courseCards} />
              <div class="HSM_ApplicationFormNote">
                <p className="wow fadeInUp"><span>NOTE : </span>Students will be allotted the batch according to the level of the student.</p>
              </div>
              <div className="HSM_WesternVocalsGroupAdv">
                <h2 className="wow fadeInUp">Advantages</h2>
                <div className="row HSM_TuitionsAln">
                  <div className="HSM_TuitionsCols wow fadeInUp col-4">
                    <div className="HSM_TuitionsColsInner">
                      <div className="HSM_CoursesCards">
                        <h6>Collaboration and Teamwork</h6>
                        <ul>
                          <li>Learn to work and create music with others.</li>
                          <li>Enhance listening skills and group coordination.</li>
                          <li>Develop the ability to adapt and contribute effectively in a collective setting.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="HSM_TuitionsCols wow fadeInUp col-4">
                    <div className="HSM_TuitionsColsInner">
                      <div className="HSM_CoursesCards">
                        <h6>Performance Skills</h6>
                        <ul>
                          <li>Build confidence and stage presence through regular group practice.</li>
                          <li>Gain experience in performing in front of peers.</li>
                          <li>Prepare for public performances and auditions.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="HSM_TuitionsCols wow fadeInUp col-4">
                    <div className="HSM_TuitionsColsInner">
                      <div className="HSM_CoursesCards">
                        <h6>Diverse Learning</h6>
                        <ul>
                          <li>Exposure to various instruments and musical styles.</li>
                          <li>Broaden musical understanding and versatility.</li>
                          <li>Experience the dynamics of different roles within an ensemble.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="HSM_TuitionsCols wow fadeInUp col-4">
                    <div className="HSM_TuitionsColsInner">
                      <div className="HSM_CoursesCards">
                        <h6>Motivation and Support</h6>
                        <ul>
                          <li>Benefit from the encouragement and camaraderie of fellow musicians.</li>
                          <li>Enjoy a more enjoyable and inspiring learning process.</li>
                          <li>Stay motivated by sharing progress and achievements with peers.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="HSM_TuitionsCols wow fadeInUp col-4">
                    <div className="HSM_TuitionsColsInner">
                      <div className="HSM_CoursesCards">
                        <h6>Enhanced Musicality</h6>
                        <ul>
                          <li>Improve timing, tuning, and coordination by playing in sync with others.</li>
                          <li>Develop a deeper understanding of ensemble dynamics.</li>
                          <li>Refine musical interpretation and expression through group feedback.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="HSM_TuitionsCols wow fadeInUp col-4">
                    <div className="HSM_TuitionsColsInner">
                      <div className="HSM_CoursesCards">
                        <h6>Social Interaction</h6>
                        <ul>
                          <li>Build lasting friendships and connections with other musicians.</li>
                          <li>Enjoy a sense of community and belonging.</li>
                          <li>Participate in collaborative projects and performances.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="HSM_TuitionsCols wow fadeInUp col-4">
                    <div className="HSM_TuitionsColsInner">
                      <div className="HSM_CoursesCards">
                        <h6>Creative Growth</h6>
                        <ul>
                          <li>Explore new ideas and approaches through group discussions and collaborations.</li>
                          <li>Encourage creativity and innovation in a supportive environment.</li>
                          <li>Learn from the diverse perspectives and experiences of peers.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="HSM_TuitionsCols wow fadeInUp col-4">
                    <div className="HSM_TuitionsColsInner">
                      <div className="HSM_CoursesCards">
                        <h6>Cost-Effective Learning</h6>
                        <ul>
                          <li>Benefit from high-quality instruction at a lower cost compared to individual lessons.</li>
                          <li>Access a variety of learning resources and materials in a group setting.</li>
                          <li>Share the cost of professional coaching and facilities with other students.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <p className="wow fadeInUp">Hurry up! Our batches are almost full.</p>
              <p className="wow fadeInUp">Contact or Call us now to check your seat availability with <span className="batchTimings">batch timings.</span></p>
            </div>
          </div>
        </div>
      </div>

      <ContactUs />

      <BookDemo />

      <FAQ />
    </>
  );
};

export default Opera;
