import React from "react";

import ContactUs from "../home/ContactUs";
import StudentSupportTabs from './StudentSupportTabs';
import WhyChooseHSM from "./WhyChooseHSM";
import CourseOffering from "./CourseOffering";

import InstaPosts from '../InstaPosts';

const fileUrl = "https://www.harshitamusicacademy.com/files";


const HomeTuitions = ({hsmOfflinePage}) => {
  return (
    <>
      <div className="KSM_Banner"
      style={{
        backgroundImage: `url('${fileUrl}/banners/home_tutions_banner.webp')`,
      }}>
        <div className="container">
          <h1 className="wow fadeInUp">HSM Offline</h1>
        </div>
      </div>

      <div className="KSM_HSMPgAbout">
        <div className="container">
          <h2 className="wow fadeInUp">{hsmOfflinePage.HSMOfflinePage_OCT_Title}</h2>
          <p className="wow fadeInUp">{hsmOfflinePage.HSMOfflinePage_OCT_Description}</p>
          <h6 className="wow fadeInUp">{hsmOfflinePage.HSMOfflinePage_OCT_SubTitle}</h6>
          <div className="KSM_HSMPgAboutMain">
            <div className="KSM_HSMPgAboutCard wow fadeInUp col-5-eq">
              <div className="KSM_HSMPgAboutCardInner">
                <p>Opera</p>
              </div>
            </div>
            <div className="KSM_HSMPgAboutCard wow fadeInUp col-5-eq">
              <div className="KSM_HSMPgAboutCardInner">
                <p>Western Vocals</p>
              </div>
            </div>
            <div className="KSM_HSMPgAboutCard wow fadeInUp col-5-eq">
              <div className="KSM_HSMPgAboutCardInner">
                <p>Indian Classical Vocals</p>
              </div>
            </div>
            <div className="KSM_HSMPgAboutCard wow fadeInUp col-5-eq">
              <div className="KSM_HSMPgAboutCardInner">
                <p>Bollywood Film Singing</p>
              </div>
            </div>
            <div className="KSM_HSMPgAboutCard wow fadeInUp col-5-eq">
              <div className="KSM_HSMPgAboutCardInner">
                <p>Playback Stage Singing</p>
              </div>
            </div>
            <div className="KSM_HSMPgAboutCard wow fadeInUp col-5-eq">
              <div className="KSM_HSMPgAboutCardInner">
                <p>Acoustic Guitar</p>
              </div>
            </div>
            <div className="KSM_HSMPgAboutCard wow fadeInUp col-5-eq">
              <div className="KSM_HSMPgAboutCardInner">
                <p>Electric Guitar</p>
              </div>
            </div>
            <div className="KSM_HSMPgAboutCard wow fadeInUp col-5-eq">
              <div className="KSM_HSMPgAboutCardInner">
                <p>Bass Guitar</p>
              </div>
            </div>
            <div className="KSM_HSMPgAboutCard wow fadeInUp col-5-eq">
              <div className="KSM_HSMPgAboutCardInner">
                <p>Flamenco Guitar</p>
              </div>
            </div>
            <div className="KSM_HSMPgAboutCard wow fadeInUp col-5-eq">
              <div className="KSM_HSMPgAboutCardInner">
                <p>Mandolin</p>
              </div>
            </div>
            <div className="KSM_HSMPgAboutCard wow fadeInUp col-5-eq">
              <div className="KSM_HSMPgAboutCardInner">
                <p>Ukulele</p>
              </div>
            </div>
            <div className="KSM_HSMPgAboutCard wow fadeInUp col-5-eq">
              <div className="KSM_HSMPgAboutCardInner">
                <p>Keyboard</p>
              </div>
            </div>
            <div className="KSM_HSMPgAboutCard wow fadeInUp col-5-eq">
              <div className="KSM_HSMPgAboutCardInner">
                <p>Piano</p>
              </div>
            </div>
            <div className="KSM_HSMPgAboutCard wow fadeInUp col-5-eq">
              <div className="KSM_HSMPgAboutCardInner">
                <p>Drums</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <WhyChooseHSM hsmOfflinePage={hsmOfflinePage} />

      <CourseOffering hsmOfflinePage={hsmOfflinePage} />

      <div className="KSM_HSMStudentSupport HSM_WCU HSM_CoursesSlider"
      style={{
        backgroundImage: `url('${fileUrl}/banners/student_support_bg.webp')`,
      }}>
        <div className="container">
          <h2 className="wow fadeInUp">{hsmOfflinePage.HSMOfflinePage_StudentSupport_MainTitle}</h2>
          <p className="wow fadeInUp">{hsmOfflinePage.HSMOfflinePage_StudentSupport_Description}</p>
          <div className="HSM_WCU_Inner">
          <StudentSupportTabs hsmOfflinePage={hsmOfflinePage} />
          </div>
        </div>
      </div>

      <div className="KSM_HSMPgAbout">
        <div className="container">
          <h2 className="wow fadeInUp">{hsmOfflinePage.HSMOfflinePage_LocationAccessibility_MainTitle}</h2>
          <p className="wow fadeInUp">{hsmOfflinePage.HSMOfflinePage_LocationAccessibility_Description}</p>
        </div>
      </div>

      <ContactUs title="Contact to visit" />

      <InstaPosts />
    </>
  );
};

export default HomeTuitions;
