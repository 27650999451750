import React, { Component } from "react";
import Slider from "react-slick";
import axios from 'axios';

export default class TestimonialsSlider extends Component {

  constructor(props) {
    super(props);
    this.state = {
      testimonials: []
    };
  }

  componentDidMount() {
    axios.get("https://www.harshitamusicacademy.com/adminPanel/API/testimonials.php")
      .then((response) => {
        this.setState({ testimonials: response.data.Testimonials });
      })
      .catch((error) => {
        console.error("There was an error fetching the testimonials!", error);
      });
  }

  render() {
    const settings = {
      dots: false,
      arrows: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      pauseOnHover: true,
      className: "rv_slider",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
            arrows: true
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
            arrows: true,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
            arrows: true,
          }
        }
      ]
    };

    const { testimonials } = this.state;

  return (
    <>
    <Slider {...settings}>
      {testimonials.map((testimonial, index) => (
        <div key={index} className="HSM_Testimonials_Cols">
          <div className="HSM_TestimonialsCard">
            <div className="HSM_TestimonialsCardIcon">
              <img
                src={`${testimonial.UsersMedia_MediaUrl}`}
                alt="testimonials"
                width={130}
                height={130}
                objectFit="contain"
              />
            </div>
            <p className="HSM_TestimonialName">{testimonial.Testimonials_UserName}</p>
            <div className="HSM_TestimonialMessage">
              <p>{testimonial.Testimonials_UserMessage}</p>
            </div>
            <p className="HSM_TestimonialCountry">{testimonial.Testimonials_UserCountry}</p>
          </div>
        </div>
      ))}
    </Slider>
    </>
   );
  }
}
