import React, { useEffect } from 'react';

import Header from "../components/Header";
import Footer from "../components/Footer";

import BlogSingle from '../components/blog/BlogSingle';

import EnrollNowButton from "../components/EnrollNowButton";

export default function BlogPageSingle() {
  
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant"
    });
  }, []);

  return (
    <>
    <EnrollNowButton />

    <Header />

    <BlogSingle />

    <Footer />
    </>
  );
}
