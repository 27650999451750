import React, {useState, useEffect} from 'react';
import axios from 'axios';

import Header from "../components/Header";
import Footer from "../components/Footer";

import MetaTags from "../components/MetaTags";

import EnrollNowButton from "../components/EnrollNowButton";

import Drums from '../components/courses/drums/Drums';

export default function DrumsCoursePage() {

  const[seo, setSeo]= useState([]);
  
  useEffect(()=>{
    axios.get("https://www.harshitamusicacademy.com/adminPanel/API/seo.php").then((response)=>{
      setSeo(response.data.SEO);
  })
  },[]);
  
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant"
    });
  }, []);

  return (
    <>
    {seo.map((res) => (
      <MetaTags
      title={`${res.SEO_Title_Drums}`}
      description={`${res.SEO_Description_Drums}`}
      keywords={`${res.SEO_Keyword_Drums}`} />
    ))}

    <EnrollNowButton />

    <Header />

    <Drums />

    <Footer />
    </>
  );
}
