import React from "react";

import WhyChooseUsSlider from "./WhyChooseUsSlider";

const fileUrl = "https://www.harshitamusicacademy.com/files";

const WhyChooseUs = ({ homePage }) => {
  return (
    <>
      <div className="HSM_WCU HSM_CoursesSlider"
      style={{
        backgroundImage: `url('${fileUrl}/banners/why_choose_us_bg.webp')`,
      }}>
        <div className="container">
          <h2 className="wow fadeInUp">{homePage.HomePage_WhyChooseUs_MainTitle}</h2>
          <p className="wow fadeInUp">{homePage.HomePage_WhyChooseUs_Description1}</p>
          <div className="HSM_WCU_Inner">
          <WhyChooseUsSlider homePage={homePage} />
          </div>
          <h6 className="wow fadeInUp">{homePage.HomePage_WhyChooseUs_SubTitle}</h6>
          <p className="wow fadeInUp">{homePage.HomePage_WhyChooseUs_Description2}</p>
        </div>
      </div>
    </>
  );
}

export default WhyChooseUs;
