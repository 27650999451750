import React from "react";

import BookDemoForm from "../BookDemoForm";

const BookDemo = () => {
  return (
    <>
      <div className="HSM_BookDemo HSM_CoursesSlider">
        <div className="container">
          <h2 className="wow fadeInUp">BOOK A DEMO</h2>
          <div className="row HSM_ContactUsAln">
            <div className="HSM_ContactUsRight col-12">
              <div className="HSM_ContactUsRightInner">
                <p className="wow fadeInUp">Interested in experiencing what Harshita School of Music has to offer firsthand? Book a complimentary demo session with one of our expert instructors! During the demo, you'll have the opportunity to :</p>
                <div className="HSM_ContactUsRightInnerListings wow fadeInUp">
                  <ul>
                    <li>Meet our faculty and learn about our teaching approach.</li>
                    <li>Experience a sample lesson tailored to your interests and skill level.</li>
                    <li>Ask questions about our programs, curriculum, and facilities.</li>
                  </ul>
                </div>
                <p className="wow fadeInUp">To schedule your demo session, please fill out the form below or contact us directly using the provided contact details. We'll get back to you promptly to confirm your booking and assist you with any inquiries you may have.</p>
                <BookDemoForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BookDemo;
