import React, {useState, useEffect} from 'react';
import axios from 'axios';

import WhatsApp from './WhatsApp';

const SocialMedia = () => {

  const[socialMedia,setSocialMedia]= useState([]);
  
  useEffect(()=>{
    axios.get("https://www.harshitamusicacademy.com/adminPanel/API/social_media.php").then((response)=>{
      setSocialMedia(response.data.SocialMedia);
  })
  },[]);

  return (
    <>
    {socialMedia.map((socialMedia, index) => (
      <li key={index}>
        <a href={`${socialMedia.SocialMedia_SocialMediaUrl}`} rel="noreferrer" target="_blank"><i className={`${socialMedia.SocialMedia_SocialIcon}`}></i></a>
      </li>
      ))}
      <WhatsApp />
    </>
  );
};

export default SocialMedia;
