import React , { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import PopularCoursesSidebar from './PopularCoursesSidebar';
import LatestPostsSidebar from './LatestPostsSidebar';
import PopularPostsSidebar from './PopularPostsSidebar';

import MetaTags from "../MetaTags";

const BlogSingle = () => {

  const {Posts_ID} = useParams();
  const [blog, setBlog] = useState([]);

  useEffect(()=>{
    axios.post("https://www.harshitamusicacademy.com/adminPanel/API/posts_single.php", {Posts_ID}).then((response)=>{
        setBlog(response.data.PostSingle);
  })
  },[Posts_ID]);

  const formatUnixTimestamp = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  };

  return (
    <>
      {blog.map((pos,index) => (
      <>
      <MetaTags
      title={`${pos.Posts_SEOTitle}`}
      description={`${pos.Posts_MetaDescription}`}
      keywords={`${pos.Posts_FocusKeyphrase}`} />

      <div className="KSM_Blog_Single KSM_Banner"
      style={{
        backgroundImage: `url('${pos.UsersMedia_MediaUrl}')`,
      }} 
      key={index}
      >
        <div className="container">
          <h1 className="wow fadeInUp">{`${pos.Posts_Title}`}</h1>
        </div>
      </div>

      <div className="KSM_Blog_SingleMain KSM_AllBlogMain">
        <div className="container">
          <h5 className="cateName">Category : <span>{`${pos.Posts_CategoryName}`}</span></h5>
          <p className="postPostedDate">Posted on : <span>{`${formatUnixTimestamp(pos.Posts_CreateUnixTime)}`}</span></p>
          <div className="row KSM_AllBlogAln">
            <div className="KSM_SignleBlogLeft KSM_AllBlogLeft col-9">
              <div className="KSM_AllBlgLeftCard">
                <div className='KSM_SingleBlogCardInner KSM_AllBlgLeftCardInner' dangerouslySetInnerHTML={{ __html: pos.Posts_Content }} />
              </div>
            </div>
            <div className="KSM_AllBlogRight col-3">
              <PopularCoursesSidebar />
              <LatestPostsSidebar />
              <PopularPostsSidebar />
            </div>
          </div>
        </div>
      </div>

      </> 
      ))}
    </>
  );
};

export default BlogSingle;
