import React from "react";
import { Link } from "react-router-dom";

const Preparatory = ({ courseCards }) => {
  return (
    <>
      <div className="row HSM_Course_paricularSelectedCardAln">
      {courseCards.map((res, index) => (
        <div className="HSM_Course_paricularSelectedCardcols wow fadeInUp col-4" key={index}>
          <div className="HSM_Course_paricularSelectedCardcolsInner">
            <h5>{res.AllCoursesCards_Card_CourseCardName}</h5>
            <div className="HSM_WhatWillBeTaught">
              <h6>What Will Be Taught</h6>
              <p>{res.AllCoursesCards_Card_CourseCardWWBT}</p>
            </div>
            <div className="HSM_WhatWillBeTaught">
              <h6>Who Can Enroll</h6>
              <ul>
                <li>{res.AllCoursesCards_Card_CourseCardWCEnroll1}</li>
                <li>{res.AllCoursesCards_Card_CourseCardWCEnroll2}</li>
              </ul>
            </div>
            <div className="HSM_WhatWillBeTaught">
              <h6>Learning Outcomes</h6>
              <ul>
                <li>{res.AllCoursesCards_Card_CourseCardLrOutcomes1}</li>
                <li>{res.AllCoursesCards_Card_CourseCardLrOutcomes2}</li>
              </ul>
            </div>
            <h6>Features</h6>
            <ul>
            {res.Features.map((feature, index) => (
              <li key={index}>
                {feature.AllCoursesCards_Features_FeatureTitle} : 
                <span>
                  {feature.AllCoursesCards_Features_FeatureValue.toLowerCase() === "yes" ? (
                      <i className="fa fa-check"></i>
                    ) : feature.AllCoursesCards_Features_FeatureValue.toLowerCase() === "no" ? (
                      <i className="fa fa-close"></i>
                    ) : (
                      feature.AllCoursesCards_Features_FeatureValue
                  )}
                </span>
              </li>
            ))}
            </ul>
            <div className="HSM_Course_paricularSelectedCardcolsInnerPay">
              <div className="HSM_Course_paricularSelectedCardcolsInnerPayLeft">
                <div className="HSM_Course_paricularSelectedCardcolsInnerSessions">
                  <h6>Number of classes</h6>
                  <p>{res.AllCoursesCards_Card_CourseCardNumberOfClasses} Classes</p>
                </div>
                {/* <div className="HSM_Course_paricularSelectedCardcolsInnerPayBtn">
                  <a href={res.UsersMedia_MediaUrl} target="_blank" rel="noreferrer">Curriculum</a>
                </div> */}
                <div className="HSM_Course_paricularSelectedCardcolsInnerPayBtn">
                  <Link to="/admission-form">Enroll Now <i className="fa fa-angle-right"></i></Link>
                </div>
              </div>
              <div className="HSM_Course_paricularSelectedCardcolsInnerPayRight">
                <div className="HSM_Course_paricularSelectedCardcolsInnerPayBtn">
                Rs.<span className="CoursePrice">{res.AllCoursesCards_Card_CourseCardBasePrice}/-</span><br/><span className="discountedCoursePrice">{res.AllCoursesCards_Card_CourseCardDiscountedPrice}/-</span>
                <br/>
                <span className="pricePerClass">Rs. {res.AllCoursesCards_Card_CourseCardChargePerClass} / Per Class</span>
                </div>
                <div class="HSM_HeaderDemoBtn">
                  <Link to="/contact" className="ankrBtn">Book A Demo <i className="fa fa-angle-right"></i></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
      </div>
    </>
  );
};

export default Preparatory;
