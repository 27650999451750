import React from "react";
import PropTypes from "prop-types";

import ContactForm from "../ContactForm";
import ContactDetails from "../ContactDetails";

const fileUrl = "https://www.harshitamusicacademy.com/files";

const ContactUs = ({ title }) => {
  return (
    <>
      <div className="HSM_ContactUs HSM_CoursesSlider"
      style={{
        backgroundImage: `url('${fileUrl}/banners/have_a_question_bg.webp')`,
      }}>
        <div className="container">
          <h2 className="wow fadeInUp">{title}</h2>
          <div className="row HSM_ContactUsAln">
            <div className="HSM_ContactUsLeft col-6">
              <div className="HSM_ContactUsLeftInner">
                <h6 className="wow fadeInUp">Get In Touch</h6>
                <p className="wow fadeInUp">Whether you're interested in enrolling in our courses, scheduling a trial lesson, or simply want to learn more about what we offer, our team is here to assist you. Please don't hesitate to contact us via phone, email, or by filling out the form.</p>
                <ContactDetails />
              </div>
            </div>
            <div className="HSM_ContactUsRight wow fadeInUp col-6">
              <div className="HSM_ContactUsRightInner">
              <ContactForm />
              </div>
            </div>
          </div>
          <p className="HSM_ContactUsBtm wow fadeInUp">We look forward to hearing from you and welcoming you to the vibrant community of musicians at Harshita School of Music. Let's make beautiful music together!</p>
        </div>
      </div>
    </>
  );
};

ContactUs.propTypes = {
  title: PropTypes.string,
};

ContactUs.defaultProps = {
  title: "HAVE A QUESTION ??",
};

export default ContactUs;
