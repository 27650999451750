import React, {useState, useEffect} from 'react';
import axios from 'axios';

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const[faqs,setFaqs]= useState([]);
  
  useEffect(()=>{
    axios.get("https://www.harshitamusicacademy.com/adminPanel/API/faqs.php").then((response)=>{
      setFaqs(response.data.Faqs);
  })
  },[]);

  const handleAccordionClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <>
      <div className="HSM_FaqPage_bg HSM_Faq HSM_CoursesSlider">
        <div className="container">
          <h2 className="wow fadeInUp">Frequently Asked Questions</h2>
          <div className="HSM_Faq_Accordian">
            {faqs.map((Faqs, index) => (
            <div key={index} className={`HSM_Faq_Accordian_Item wow fadeInUp ${activeIndex === index ? 'active' : ''}`}>
              <button className="HSM_Faq_Accordian_Question" onClick={() => handleAccordionClick(index)}>
                {Faqs.Faqs_Question}
              </button>
              <div className={`HSM_Faq_Accordian_Answer wow fadeInUp ${activeIndex === index ? 'active' : ''}`} dangerouslySetInnerHTML={{ __html: Faqs.Faqs_Answer }} />
            </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default FAQ;
