import React from "react";

import { Link } from "react-router-dom";

import AdmissionForm from "./application-form/AdmissionForm";
import BankDetails from "./application-form/BankDetails";

const fileUrl = "https://www.harshitamusicacademy.com/files";

const ApplicationFormPage = ({admFormPage}) => {
  return (
    <>
      <div className="KSM_AdmFormBanner KSM_Banner"
      style={{
        backgroundImage: `url('${fileUrl}/banners/admission_form_banner.webp')`,
      }}>
        <div className="container">
          <h1 className="wow fadeInUp">Enroll Now!</h1>
        </div>
      </div>

      <div className="HSM_ApplicationForm HSM_BookDemo HSM_CoursesSlider">
        <div className="container">
          <h2 className="wow fadeInUp">Application Form</h2>
          <div className="row HSM_ContactUsAln">
            <div className="HSM_ContactUsRight col-12">
              <div className="HSM_ContactUsRightInner">
                <p className="wow fadeInUp">{admFormPage.AdmissionFormPage_ApplicationForm_Desc1}</p>
                <h5 className="wow fadeInUp">PROCESS OF COMPLETING YOUR ADMISSION :-</h5>
                <div className="HSM_MasteryCourseListing">
                  <ul>
                    <li className="wow fadeInUp">
                      <div className="HSM_ApplicationFormProcessNumber">
                        <h6 className="listingTitle">Complete Payment</h6>
                        <div className="HSM_ApplicationFormProcessNum">
                          1
                        </div>
                      </div>
                      <div className="HSM_ApplicationFormGuideIcon">
                        <img 
                        src={`${fileUrl}/icons/payment_complete_black.png`}
                        alt="guide-icon"
                        width={60}
                        height={60}
                        objectFit="contain" />
                      </div>
                      <span className="listingText">Complete the tuition fee payment first & take a screenshot of it to attach in the form.</span>
                    </li>
                    <li className="wow fadeInUp">
                      <div className="HSM_ApplicationFormProcessNumber">
                        <h6 className="listingTitle">Fill Application Form</h6>
                        <div className="HSM_ApplicationFormProcessNum">
                          2
                        </div>
                      </div>
                      <div className="HSM_ApplicationFormGuideIcon">
                        <img 
                        src={`${fileUrl}/icons/fill_form_black.png`}
                        alt="guide-icon"
                        width={60}
                        height={60}
                        objectFit="contain" />
                      </div>
                      <span className="listingText">Fill the application form and attatch the payment screenshot in the application form.</span>
                    </li>
                    <li className="wow fadeInUp">
                      <div className="HSM_ApplicationFormProcessNumber">
                        <h6 className="listingTitle">Read Terms & Conditions</h6>
                        <div className="HSM_ApplicationFormProcessNum">
                          3
                        </div>
                      </div>
                      <div className="HSM_ApplicationFormGuideIcon">
                        <img 
                        src={`${fileUrl}/icons/terms_conditions_black.png`}
                        alt="guide-icon"
                        width={60}
                        height={60}
                        objectFit="contain" />
                      </div>
                      <span className="listingText">Read the Terms and condition, Class Policy carefully in section 2 of the form.</span>
                    </li>
                    <li className="wow fadeInUp">
                      <div className="HSM_ApplicationFormProcessNumber">
                        <h6 className="listingTitle">Collect Invoice</h6>
                        <div className="HSM_ApplicationFormProcessNum">
                          4
                        </div>
                      </div>
                      <div className="HSM_ApplicationFormGuideIcon">
                        <img 
                        src={`${fileUrl}/icons/collect_invoice.png`}
                        alt="guide-icon"
                        width={60}
                        height={60}
                        objectFit="contain" />
                      </div>
                      <span className="listingText">After completing admission process, collect your invoice from academy.</span>
                    </li>
                    <li className="wow fadeInUp">
                      <div className="HSM_ApplicationFormProcessNumber">
                        <h6 className="listingTitle">Start Learning</h6>
                        <div className="HSM_ApplicationFormProcessNum">
                          5
                        </div>
                      </div>
                      <div className="HSM_ApplicationFormGuideIcon">
                        <img 
                        src={`${fileUrl}/icons/start_learning.png`}
                        alt="guide-icon"
                        width={60}
                        height={60}
                        objectFit="contain" />
                      </div>
                      <span className="listingText">Start your musical learning journey with us!</span>
                    </li>
                  </ul>
                </div>
                <div className="HSM_ApplicationFormNote wow fadeInUp">
                  <p><span>NOTE : </span>{admFormPage.AdmissionFormPage_ApplicationForm_Note}</p>
                </div>

                <BankDetails />

                <p className="HSM_ApplicationFormBankInst wow fadeInUp">
                  <span>*</span>Contact Us now for bank details for direct transfer to bank account either through Call , Email or Whatsapp.
                </p>

                <AdmissionForm />
                
                <div className="HSM_ApplicationFormBtm wow fadeInUp">
                  <p>{admFormPage.AdmissionFormPage_ApplicationForm_Desc2}</p>
                  <p>{admFormPage.AdmissionFormPage_ApplicationForm_Desc3}</p>
                  <p>{admFormPage.AdmissionFormPage_ApplicationForm_Desc4}</p>
                </div>
                <div className="HSM_Terms_Conditions_Btn HSM_HeaderDemoBtn wow fadeInUp">
                  <Link to="/terms-conditions" className="ankrBtn">Terms & Conditions</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApplicationFormPage;
