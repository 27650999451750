import React, { useEffect, useState } from 'react';
import axios from 'axios';

const fileUrl = "https://www.harshitamusicacademy.com/files";

const InstaPosts = () => {
  
    const [instaFeedPosts, setInstaFeedPosts] = useState([]);
    const [accessToken, setAccessToken] = useState('');

    useEffect(()=>{
      axios.get("https://www.harshitamusicacademy.com/adminPanel/API/social_media.php").then((response)=>{
        const instagram = response.data.SocialMedia.find(item => item.SocialMedia_SocialIcon === "fa fa-instagram");
        if(instagram){
          setAccessToken(instagram.SocialMedia_SocialMediaAccessToken);
        }
    })
    },[]);

    useEffect(() => {
      const fetchPosts = async () => {
        try {
          const response = await axios.get(`https://graph.instagram.com/me/media`, {
            params: {
              fields: 'id,caption,media_type,media_url,thumbnail_url,permalink',
              access_token: accessToken,
              limit: 9,
            },
          });
          setInstaFeedPosts(response.data.data);
        } catch (error) {
          console.error('Error fetching Instagram posts:', error);
        }
      };

      if (accessToken) {
        fetchPosts();
      }
    }, [accessToken]);

  return (
    <>
      <div className="HSM_InstaFeed KSM_AllBlogMain">
        <div className="container">
            <h2>Follow Us On Instagram</h2>
            <div className="row KSM_AllBlogAln">
                <div className="KSM_AllBlogLeft col-6">
                    <div className="KSM_AllBlgLeftCard wow fadeInUp">
                        <div className='KSM_AllBlgLeftCardInner'>
                            <div className="KSM_AllBlgLeftCardInnerContent">
                                <img 
                                src={`${fileUrl}/insta_feed_QR.png`}
                                alt="instafram-QR"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="KSM_AllBlogRight col-6">
                    <div className="HSM_InstafeedAln">
                        {instaFeedPosts.map(post => (
                        <div className="HSM_InstafeedCards col-4" key={post.id}>
                            <div className="HSM_InstafeedCardBox">
                                {post.media_type === 'IMAGE' || post.media_type === 'CAROUSEL_ALBUM' ? (
                                    <img src={post.media_url} alt={post.caption} />
                                    ) : (
                                    <video controls>
                                        <source src={post.media_url} type="video/mp4" />
                                    </video>
                                )}
                            </div>
                        </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
      </div>
    </>
  );
};

export default InstaPosts;
