import React from "react";
import OwnerProfile from "./OwnerProfile";

const Profile = ({aboutPage}) => {
  return (
    <>
    <div className="HSM_About_Vision_Tp HSM_AboutProfile HSM_AboutUs HSM_CoursesSlider wow fadeInUp">
        <div className="container">
            <p>{aboutPage.AboutUsPage_Description1}</p>
            <p>{aboutPage.AboutUsPage_Description2}</p>
        </div>
    </div>
    <div className="HSM_About_Vision HSM_AboutProfile HSM_CoursesSlider">
        <div className="container">
            <div className="HSM_AboutProfileVision wow fadeInUp">
                <h3>{aboutPage.AboutUsPage_OurVision_MainTitle}</h3>
                <p>{aboutPage.AboutUsPage_OurVision_Description}</p>
            </div>
        </div>
    </div>
    <div className="HSM_AboutProfile HSM_AboutUs HSM_CoursesSlider wow fadeInUp">
        <div className="container">
            <div className="HSM_AboutProfileApart">
                <h3>{aboutPage.AboutUsPage_WhatSetsUsApart_MainTitle}</h3>
                <div className="HSM_MasteryCourseListing">
                    <ul>
                        <li><h6 className="listingTitle">{aboutPage.AboutUsPage_WhatSetsUsApart_FeatureTitle1}</h6><span className="listingText">{aboutPage.AboutUsPage_WhatSetsUsApart_FeatureDescription1}</span></li>
                        <li><h6 className="listingTitle">{aboutPage.AboutUsPage_WhatSetsUsApart_FeatureTitle2}</h6><span className="listingText">{aboutPage.AboutUsPage_WhatSetsUsApart_FeatureDescription2}</span></li>
                        <li><h6 className="listingTitle">{aboutPage.AboutUsPage_WhatSetsUsApart_FeatureTitle3}</h6><span className="listingText">{aboutPage.AboutUsPage_WhatSetsUsApart_FeatureDescription3}</span></li>
                        <li><h6 className="listingTitle">{aboutPage.AboutUsPage_WhatSetsUsApart_FeatureTitle4}</h6><span className="listingText">{aboutPage.AboutUsPage_WhatSetsUsApart_FeatureDescription4}</span></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    
    <OwnerProfile />
    </>
  );
};

export default Profile;
