import React from 'react';
import { Link } from "react-router-dom";

const fileUrl = "https://www.harshitamusicacademy.com/files";

const TrainingCenter = ({ homePage }) => {

  return (
    <>
      <div className="HSM_Training_Center HSM_WCU HSM_CoursesSlider"
      style={{
        backgroundImage: `url('${fileUrl}/banners/opera_training_center.webp')`,
      }}>
        <div className="container">
            <h2 className="wow fadeInUp">{homePage.HomePage_OperaTrainingCenter_Title}</h2>
            <h6 className="wow fadeInUp" dangerouslySetInnerHTML={{ __html: homePage.HomePage_OperaTrainingCenter_SubTitle }} />
            <h4 className="wow fadeInUp">{homePage.HomePage_OperaTrainingCenter_TeacherName}</h4>
            <div className='HSM_Training_CenterDesc wow fadeInUp'>
                <p>{homePage.HomePage_OperaTrainingCenter_Description1}</p>
                <p>{homePage.HomePage_OperaTrainingCenter_Description2}</p>
                <p>{homePage.HomePage_OperaTrainingCenter_Description3}</p>
            </div>
            <div className="HSM_HeaderDemoBtn wow fadeInUp">
                <Link to="/opera" className="ankrBtn">Learn Opera <i className="fa fa-angle-right"></i></Link>
            </div>
        </div>
      </div>
    </>
  );
}

export default TrainingCenter;