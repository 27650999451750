import React from "react";
import { Link } from "react-router-dom";

const EnrollNowButton = () => {
  return (
    <>
    <div className="HSM_EnrollNowFixed">
        <Link to="/admission-form">Enroll Now <i className="fa fa-angle-right"></i></Link>
    </div>
    </>
  );
};

export default EnrollNowButton;
