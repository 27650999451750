import React from "react";

import { Link } from "react-router-dom";

import AdvantagesSlider from "./AdvantagesSlider";

const Advantages = ({ homePage }) => {
  return (
    <>
      <div className="HSM_Advantages HSM_CoursesSlider">
        <div className="container">
          <h2 className="wow fadeInUp">{homePage.HomePage_HSMAdvantages_MainTitle}</h2>
          <div className="HSM_MasteryCourseListing">
              <AdvantagesSlider homePage={homePage} />
          </div>
          <div className="HSM_HeaderDemoBtn wow fadeInUp">
            <Link to="/advantages" className="ankrBtn">Read More</Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Advantages;