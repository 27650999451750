import React from "react";

import { Link } from "react-router-dom";

const ErrorPage = () =>{
    return(
        <>
        <div className="KSM_Banner">
            <div className="container">
                <h1>Page Not Found</h1>
                <div className="KSM_404Main">
                    <div className="HSM_HeaderDemoBtn">
                        <Link to="/" className="ankrBtn">Back To Home</Link>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default ErrorPage