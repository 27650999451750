import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Link } from "react-router-dom";
import Slider from "react-slick";

const CoursesSlider = () => {

    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      pauseOnHover: true,
      className: "rv_slider",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        }
      ]
    };

  const[coursesCarousel, setCoursesCarousel]= useState([]);

  useEffect(()=>{
    axios.get("https://www.harshitamusicacademy.com/adminPanel/API/course-cards-carousel.php").then((response)=>{
      setCoursesCarousel(response.data.CourseCarouselCards);
    })
  },[]);

  return (
    <>
    <div className="HSM_CoursesSlider HSM_CoursesBg">
      <div className="container">
        <h2 className="wow fadeInUp">Our Online Music Courses</h2>
        <div className="HSM_CoursesSliderMain">
          <Slider {...settings}>
          {coursesCarousel.map((coursescarousel, index) => (
              <div className="HSM_CoursesCards wow fadeInUp" key={index}>
                <div className="HSM_CourseCardsImg">
                  <img 
                  src={coursescarousel.UsersMedia_MediaUrl} 
                  alt={coursescarousel.CourseCardsCarousel_Title} />
                </div>
                <div className="HSM_CoursesCardsInner">
                  <h6>{coursescarousel.CourseCardsCarousel_Title}</h6>
                  <p>{coursescarousel.CourseCardsCarousel_Description}</p>
                  <div className="HSM_HeaderDemoBtn">
                    <Link to={`/${coursescarousel.CourseCardsCarousel_PageLinkTo}`} className="ankrBtn">Explore <i className="fa fa-angle-right"></i></Link>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
    </>
   );
}

export default CoursesSlider;
