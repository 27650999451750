import React, { useState, useEffect } from "react";
import axios from 'axios';

const AllSoloCardsInstruction = () => {

  const[instructions, setInstructions]= useState([]);

  useEffect(()=>{
    axios.get("https://www.harshitamusicacademy.com/adminPanel/API/instructions.php").then((response)=>{
      setInstructions(response.data.Instructions);
  })
  },[]);

  const isOdd = instructions.length % 2 !== 0;

  const [isActive, setIsActive] = useState(false);

  const handleToggle = () => {
    setIsActive(!isActive);
  };

  return (
    <>
    <div className={`HSM_Course_paricularSelectedSoloInstructions wow fadeInUp ${isActive ? "active" : ""}`}>
      <div className={`HSM_Course_particularContent ${isActive ? "active" : ""}`}>
        <div className="HSM_Course_paricularSelectedSoloInstructionsSinStar">

          {instructions.map((res, index) => (
              <p 
                key={index}
                style={isOdd && index === instructions.length - 1 ? { width: '100%' } : {}}
              >
                <span className="starSingle">{res.Instructions_Title} : </span>
                {res.Instructions_Description}</p>
          ))}

        </div>
      </div>
      <div className={`HSM_Course_InstArrow ${isActive ? "active" : ""}`} onClick={handleToggle}><i className="fa fa-arrow-down"></i></div>
    </div>
    </>
  );
};

export default AllSoloCardsInstruction;
